@import url(https://fonts.googleapis.com/css2?family=Fira+Code:wght@400;500;600;700&family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap);
:root {
  /* --- primary colors --- */
  /* --- LIGHT MODE --- */
  --primary-color: #7567a3;     
  --secondary-color: #ffffff; 
  --tertiary-color: #000000;

  --primary-color-1: #C9C2D5;   /* light */
  --primary-color-2: #9075b0;   /* moderate */
  --primary-color-3: #a08fd1;   /* dark */
  --primary-color-4: Linear-gradient(to bottom,#fff,#4D4287);
  --primary-color-5: Linear-gradient(to top,#fff,#4D4287);
   /* dark dark */

  --primary-text-color: #000000f7; 
  --secondary-text-color: #ffffff;  
  
  --header-secundary-color: #fcfbfe;

  /* --- primary colors --- */
  /* --- DARK MODE --- */
  /* --primary-color: #7d5e8c;
  --secondary-color: #1a1a1a;
  --tertiary-color: #ffffff;

  --primary-color-1: #b19fbf;
  --primary-color-2: #8a6b97;
  --primary-color-3: #63476f;
  --primary-color-4: #463455;

  --primary-text-color: #ffffff;
  --secondary-text-color: #000000f7;

  --header-secundary-color: #1a1a1a; */

  /* --- outras cores --- */
  --light-orange-color: #eec170;
  --dark-orange-color: #fca72fe1;
  --green-color: #006400;
  --2-green-color: #007200;
  --light-blue-color: #05d9cf;
  --2-light-blue-color: #02fbee;
  --dark-blue-color: #03045e;
  --2-dark-blue-color: #010497b4;
  --dark-red-color: #b11b2a;
  --2-dark-red-color: #c71d2e;
  --border-gray-color: #7a7a7a;

  --h1-font-size: 1.6rem; /* 24px titulos grandes*/
  --h2-font-size: 1.5rem; /* 23px titulos*/
  --h3-font-size: 1.1rem; /* 18px texto destaque*/
  --p-font-size: 1rem; /* 16px textos geral*/
  --h6-font-size: .8rem; /* 14px textos pequenos*/
}

::-webkit-scrollbar{
  width: 8px;
  background-color: #ffffff;
  background-color: var(--secondary-color);
  border: none;
}
::-webkit-scrollbar-thumb{
  background-color: #7a7a7a;
  background-color: var(--border-gray-color);
  border-radius: 50px;
  height: 10vh;
}

body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Poppins', Arial, Helvetica, sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
a, button{
  color: var(--primary-text-color);
  text-decoration: none;
  cursor: pointer;
}

.body-home h1{
  color: var(--primary-color-3);
  border: 3px solid var(--primary-color-3);
  font-size: var(--h1-font-size);
  padding: 5px 22px;
  margin: 0 0 3% 0;
  transition: 0.3s ease;
}
.body-home h1:hover{
  box-shadow: 0px 0px 20px var(--primary-color-3);
}

.body-home h2{
  font-size: var(--h2-font-size);
  font-weight: 700;
  text-align: center;
}

.body-home h3{
  font-size: var(--h3-font-size);
}

p{
  font-size: var(--p-font-size);
  font-weight: 500;
}

h6{
  font-size: var(--h6-font-size); 
}

.imagem-body{
    background: url(/static/media/fundo.02989aec.jpg);
    background-attachment: fixed;
}
.body-home{
    height: 100%;
    color: var(--primary-text-color);
    display: flex;
    flex-direction: column;
}


.video-home, #quem-somos, #nosso-proposito, #como-funciona, 
#nossa-trilha, #nossa-historia, #cases-de-sucesso, #parceiros{
    border-top: 1px solid;
    border-bottom: 1px solid;
    border-color: var(--tertiary-color);
    height: 100%;
}


.scroll-to-top {
    position: fixed;
    bottom: 30px;
    right: 30px;
    cursor: pointer;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.3s ease-out;
}
.scroll-to-top.show {
    opacity: 1;
    visibility: visible;
}
.icon-up{
    font-size: 39px;
    padding: 7px;
    background-color: var(--primary-color-1);
    border-radius: 5px;
    transition: 0.3s ease-out;
    color: var(--tertiary-color);
}
.icon-up:hover{
    background-color: var(--primary-color-2);
    -webkit-transform: scale(1.05);
            transform: scale(1.05);
}


.video-home{
    padding-top: 2vh;
    height: 120vh;
    /* background: var(--primary-color-4); */
    background: #C5BEEA;
    background: radial-gradient(at center, #C5BEEA, #2F2A49);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}
.video-home iframe{
    width: 950px;
    height: 535px;
    margin: 7vh 0 65px 0;
    border-radius: 10px;
}
.video-home a{
    margin-bottom: 4vh;
}
.video-home button{
    background-color: var(--secondary-color);
    color: var(--primary-text-color);
    border: none;
    padding: 1vh 3vw;
    font-weight: 600;
    border-radius: 4px;
    font-size: 1.1rem;
    box-shadow: 1px 1px 10px var(--tertiary-color);
    transition: 0.4s ease-in-out;
}


#quem-somos{
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 6% 40px 6% 40px;
    grid-gap: 40px;
    gap: 40px;
    color: var(--secondary-text-color);
    background-color: #ffffff;
    border: none
}

.quem-somos-descricao {
    display: flex;
    flex-direction: column;
    align-items: start;
    padding: 0px 0 20px 0;
    color: var(--secondary-text-color);
    background-color: #ffffff;
    width: 70%;
}

.circle-doted {
    width: 300px !important;
    height: 300px;
    border: dotted 3px #CBC5E2;
    border-radius: 50%;
    padding: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.circle-container {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    border: solid 8px #d7d2eb;
    display: flex;
    align-items: center;
    justify-content: center;
}

.circle-doted-trilha {
    width: 225px !important;
    height: 225px;
    border: dotted 3px #e6e2f3;
    border-radius: 50%;
    padding: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.circle-container-trilha {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    border: solid 8px #e6e2f3;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    
}

.circle-container-trilha span {
    color: #ffffff;
    font-size: 21px;
    font-weight: 450;
    margin-bottom: 4px;
}

.quem-somos-title {
    color: #605891;
}

#quem-somos h2{
    width: 100%;
    color: #111;
    text-align: left;
}
#quem-somos h1{
    margin-top: 3%;
    color: #111;
}
#quem-somos h3{
    width: 100%;
    text-align: justify;
    color: #111;
    font-weight: 400;
}


#nosso-proposito{
    background-color: var(--secondary-color);
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 8% 7% 5% 5%;
    color: var(--primary-text-color);
}
#nosso-proposito .codeString{
    width: 50%;
    font-size: var(--h2-font-size);
    font-family: 'Fira Code';
    font-weight: 700;
    transition: 0.3s ease;
    color: var(--primary-text-color);
}
#nosso-proposito .codeString:hover{
    text-shadow: 0px 0px 15px var(--tertiary-color);
}
#nosso-proposito .codeString span{
    padding-left: 3%;
}
#nosso-proposito div{
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: justify;
}
#nosso-proposito div h3{
    text-align: justify;
    margin-top: 4%;
}


/* #como-funciona{
    background-color: var(--cor-extra-clara);
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 8% 0 6% 0;
} */


#nossa-trilha{
/*
* Created with https://www.css-gradient.com
* Gradient link: https://www.css-gradient.com/?c1=e2dff5&c2=948db6&gt=l&gd=dtt
*/
/*
* Created with https://www.css-gradient.com
* Gradient link: https://www.css-gradient.com/?c1=e2dff5&c2=948db6&gt=l&gd=dbb
*/
background: #E2DFF5;
background: linear-gradient(0deg, #E2DFF5, #948DB6);
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    padding: 6% 4% 6% 4%;
    color:white;
    border: none
}

#branco{
    color:white;
}

#nossa-trilha h1{
    color:white;
}

.trilha-cards{
    display: flex;
    grid-gap: 20px;
    gap: 20px;
    margin-top: 2%;
}
.container{
    height: 410px; 
    width: 300px;
    position: relative;
    -webkit-perspective: 1000px;
            perspective: 1000px;
}
.container:hover .front{
    -webkit-transform: rotateY(-180deg);
            transform: rotateY(-180deg);
}
.container:hover .back{
    -webkit-transform: rotateY(0deg);
            transform: rotateY(0deg);
}
.front,.back{
    height: 74%; 
    width: 85%;
    position: absolute;
    -webkit-backface-visibility: hidden;
            backface-visibility: hidden;
    -webkit-transform-style: preserve-3d;
            transform-style: preserve-3d;
    transition: all 1s ease;
    border-radius: 10px;
}
.front{
    -webkit-transform: rotateY(0deg);
            transform: rotateY(0deg);
    background-color: var(--primary-color-2); 
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;  
}
.front h3{
    font-size: var(--h2-font-size);
}
.front img{
    width: 35%;
    margin-top: 2%;
}
.back{
    -webkit-transform: rotateY(180deg);
            transform: rotateY(180deg);
    background-color: var(--primary-color-3);
    display: flex;
    flex-direction: column;
    padding: 5%;
}
.back div{
    display: flex;
    align-items: center;
}
.back div p{
    margin-top: 10%;
    margin-left: 4%;
    transition: 0.7s ease-in-out;
}
.back div .bootstrap{
    width: 16%;
    margin-left: 2%;
}
.back img{
    width: 20%;
}
.back-ferramentas{
    flex-wrap: wrap;
}
.back-ferramentas div{
    width: 50%;
}
.back-ferramentas div img{
    width: 27%;
}


#nossa-historia{
    background-color: var(--primary-color-2);
    display: flex;
    align-items: center;
    justify-content: space-around;
    padding: 5% 4%;
    color: var(--primary-text-color);
}
#nossa-historia h3{
    width: 90%;
    text-align: justify;
    margin-top: 4%;
}
.historia-texto{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 50%;
}
.carossel-historia{
    width: 40%;
}
.carousel-item img{
    border-radius: 10px;
    height: 44vh;
}
.carossel-historia-previous{
    position: absolute;
    margin-right: 180% !important;
}
.carossel-historia-next{
    position: absolute;
    margin-left: 180% !important;
}


#cases-de-sucesso{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding: 5% 4% 0 4% !important;
    background-color: var(--tertiary-color);
    color: var(--secondary-text-color);
}
.carossel-cases{
    width: 55%;
    height: 65vh;
    padding: 0 2%;
}  
.cases-card img{
    width: 10vw;
    height: auto !important;
    border-radius: 50% !important;
}
.cases-card p{
    text-align: justify;
    color: var(--secondary-text-color) !important;
}
.cases-card h6{
    color: var(--primary-color-2);
    font-size: 30px !important;
    font-weight: 600;
}
.cases-card div h6 p{
    margin-top: 2%;
    font-size: var(--h3-font-size);
    font-weight: 600;
    color: var(--secondary-text-color);
}
.cases-card div h6 h5{
    font-size: var(--h6-font-size);
    margin-top: -3%;
}
.cases-card div h6 a{
    position: absolute;
    font-size: var(--p-font-size);
    transition: 0.7s ease-in-out;
}
.cases-card div h6 a:hover{
    text-decoration: underline;
}
.cases-card-div{
    display: flex;
    align-items: center;
    grid-gap: 4%;
    gap: 4%;
    margin-bottom: 4%;
}



/* #parceiros{
    display: flex;
    background-color: var(--cor-extra-clara);
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding: 5% 4%;
}
#parceiros div{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 1%;
    gap: 8%;
}
#parceiros div a{
    transition: 0.3s ease-in-out;
}
#parceiros div a:hover{
    transform: scale(1.07);
}
#parceiros div a img{
    width: 12vw;
} */


.footer{
    padding: 5% 6% 3% 6%;
    background-color: var(--secondary-color);
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 100%;
}
.footer h6{
    font-size: var(--h3-font-size);
    font-weight: 600;
    color: var(--primary-color-3);
}
.footer p{
    margin: 0;
    color: var(--primary-text-color);
}
.footer-div{
    width: 100%;
    display: flex;
    justify-content: space-between;
}
.footer-unidade{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}
.footer-unidade p:nth-child(2){
    padding: 0;
}
.footer-unidade p:nth-child(3){
    margin: 2% 0 12% 0;
    padding: 0;
}
.footer-unidade div{
    width: 100%;
    display: flex;
}
.footer-unidade svg{
    font-size: 35px;
    margin-right: 0.6vw;
    transition: 0.3s ease-in-out;
    color: var(--primary-color-3);
}
.footer-unidade svg:hover{
    -webkit-transform: scale(1.12);
            transform: scale(1.12);
}
.footer-participar{
    display: flex;
    flex-direction: column;
    text-align: center;
}
.footer-participar a{
    margin-bottom: 4%;
    font-size: var(--p-font-size);
    transition: 0.5s ease-in-out;
    text-align: center;
    font-weight: 500;
}
.footer-participar a:hover{
    text-decoration: underline;
}
.map-container {
    width: 30vw;
    height: 40vh;
}
.footer span{
    margin-top: 3%;
    color: var(--primary-text-color);
    font-weight: 600;
    font-size: var(--h6-font-size);
}



/* celular */
@media screen and (max-width: 767px){
    :root {
        --h1-font-size: 1.5rem; /* 24px titulos azuis*/
        --h2-font-size: 1.4rem; /* 23px titulos*/
    }
    .body-home h1{
        margin: 0 0 5% 0;
        padding: 5px 20px;
    }

    .icon-up{
        display: none;
    }

    .video-home{
        height: 55vh;
    }
    .video-home iframe{
        width: 355px;
        height: 200px;
        margin: 7vh 0 45px 0;
    }
    .video-home a{
        margin-bottom: 0vh;
    }
    .video-home button{
        padding: 0.8vh 6vw;
        font-size: 1rem;
    }
    
    #quem-somos{
        padding: 10% 0;
    }
    #quem-somos h2{
        width: 90%;
        margin-bottom: 4%;
        font-size: 1.1rem;
    }
    #quem-somos h1{
        margin-top: 3%;
        font-size: 1.2rem;
    }
    #quem-somos h3{
        width: 80%;
    }

    #nosso-proposito{
        flex-direction: column;
        padding-bottom: 10%;
    }
    #nosso-proposito .codeString{
        width: 100%;
        font-size: var(--h3-font-size);
        margin-top: 3%;
    }
    #nosso-proposito h1{
        margin-top: 5%;
        font-size: 1.2rem;
        text-align: center;
    }
    #nosso-proposito div{
        width: 90%;
    }

    #como-funciona h1{
        font-size: 1.2rem;
    }

    #como-funciona img{
        width: 90%;
    }

    #nossa-trilha{
        padding-top: 10%;
    }
    #nossa-trilha h1{
        font-size: 1.2rem;
    }
    .trilha-cards{
        flex-wrap: wrap;
        margin-left: 4%;
    }
    .container{
        height: 340px; 
        width: 270px;
    }
    .front,.back{
        height: 90%; 
    }
    .back div p{
        font-size: 0.9rem;
    }
        
    #nossa-historia{
        flex-direction: column;
        padding: 5% 0 10% 0;
    }
    #nossa-historia h1{
        margin-top: 5%;
        font-size: 1.2rem;
    }
    #nossa-historia h3{
        margin-bottom: 10%;
    }
    .historia-texto{
        width: 90%;
    }
    .carossel-historia{
        width: 80%;
    }
    .carousel-item img{
        height: 20vh;
    }
    .carossel-historia-previous{
        display: none;
    }
    .carossel-historia-next{
        display: none;
    }

    #cases-de-sucesso{
        padding: 10% 0 !important;
    }
    #cases-de-sucesso h1{
        font-size: 1.2rem;
    }
    .carossel-cases{
        width: 95%;
        height: 100%;
        margin-top: 5%;
    }
    .cases-card img{
        width: 20vw;
    }
    .cases-card p{
        text-align: start;
        font-size: 0.9rem;
        margin-top: 10%;
    }
    .cases-card h6{
        font-size: 22px !important;
        flex-direction: row;
    }
    #parceiros{
        padding: 10% 0;
    }
    #parceiros h1{
        text-align: center;
        font-size: 1.2rem;
        width: 65%;
    }
    #parceiros div{
        display: flex;
        flex-wrap: wrap;
    }
    #parceiros div a img{
        width: 20vw;
    }
    #parceiros div a:nth-child(1){
        margin-bottom: 2vh;
    }
    #parceiros div a:nth-child(2){
        margin-bottom: 2vh;
    }
    #parceiros div a:nth-child(3){
        margin-bottom: 2vh;
    }
    
    .footer{
        padding-top: 11%;
        padding-bottom: 7%;
    }
    .footer p{
        text-align: center;
    }
    .footer-div{
        flex-direction: column;
        align-items: center;
    }
    .footer-unidade{
        align-items: center;
    }
    .footer-unidade div{
        display: flex;
        justify-content: center;
        grid-gap: 3%;
        gap: 3%;
        margin: -6% 0 10% 0;
    }
    .footer-unidade svg{
        font-size: 37px;
    }
    .footer-participar{
        align-items: center;
        margin-bottom: 5%;
    }
    .footer-participar a{
        text-decoration: underline;
    }
    .map-container {
        width: 80vw;
        height: 30vh;
    }
    .footer span{
        margin-top: 9%;
        text-align: center;
    }
}



/* tablet */
@media screen and (min-width: 768px) and (max-width: 1080px){
    .links-header img{
        width: 70px;
        margin-left: 3vw;
    }

    .botoes-header button{
        padding: 0.9vh 3vw;
        height: auto;
        margin-right: 1vw;
        border-radius: 5px;
    }

    .icon-up{
        display: none;
    }

    .video-home{
        height: 55vh;
    }
    .video-home iframe{
        width: 355px;
        height: 200px;
        margin: 7vh 0 45px 0;
    }
    .video-home a{
        margin-bottom: 0vh;
    }
    .video-home button{
        padding: 0.8vh 6vw;
        font-size: 1rem;
    }


    #quem-somos h2{
        width: 85%;
    }
    #quem-somos h3{
        width: 85%;
    }
    #nosso-proposito{
        flex-direction: column;
    }
    #nosso-proposito .codeString{
        width: 80%;
        margin-left: 5%;
        margin-bottom: 8%;
    }
    #nosso-proposito div{
        width: 90%;
    }
    #como-funciona img{
        width: 90%;
    }
    .trilha-cards{
        flex-wrap: wrap;
    }
    .container{
        height: 390px; 
        width: 310px;
    }
    .front,.back{
        height: 90%; 
    }
    #nossa-historia{
        flex-direction: column;
    }
    #nossa-historia h3{
        margin-bottom: 7%;
    }
    .historia-texto{
        width: 100%;
    }   
    .carossel-historia{
        width: 70%;
    }
    .carousel-item img{
        height: 28vh;
    }
    .carossel-historia-previous{
        display: flex;
    }
    .carossel-historia-next{
        display: flex;
    }
    .carossel-cases{
        width: 80%;
        height: 100%;
        margin-top: 5%;
    }  
    .cases-card img{
        width: 20vw;
    }
    .cases-card p{
        margin-top: 5%;
    }
    #parceiros div{
        display: flex;
        flex-wrap: wrap;
    }
    #parceiros div a img{
        width: 20vw;
    }
    #parceiros div a:nth-child(1){
        margin-bottom: 2vh;
    }
    #parceiros div a:nth-child(2){
        margin-bottom: 2vh;
    }
    #parceiros div a:nth-child(3){
        margin-bottom: 2vh;
    }
    .footer{
        padding-bottom: 5%;
    }
    .footer p{
        text-align: center;
    }
    .footer-div{
        flex-wrap: wrap;
    }
    .footer-unidade{
        align-items: center;
        width: 50%;
    }
    .footer-unidade div{
        display: flex;
        justify-content: center;
        grid-gap: 3%;
        gap: 3%;
        margin: -6% 0 10% 0;
    }
    .footer-unidade svg{
        font-size: 37px;
    }
    .footer-participar{
        align-items: center;
        margin-bottom: 5%;
        width: 50%;
    }
    .footer-participar a{
        text-decoration: underline;
    }
    .map-container {
        margin-left: 10%;
        margin-top: 5%;
        width: 70vw;
        height: 30vh;
    }
    .footer span{
        margin-top: 6%;
    }
} 
.header-principal section #header-web{
    padding: 1% 2.5%;
    
}
.header-principal section #header-web:last-child{
    margin-right: 6vw !important;
}
.header-principal{
    background-color: var(--header-secundary-color);
    padding: 2% 4%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: fixed;
    z-index: 5;
    width: 100%;
    height: 10vh;
}
.header-principal img{
    width: 100px;
}
.header-principal li{
    list-style-type: none;
}
.header-principal section{
    display: flex;
    align-items: center;
    white-space: nowrap;
    font-size: 17px;
    color: var(--primary-text-color);
    font-weight: 600 !important;
}
.header-principal button{
    background-color: var(--primary-color);
    border: none;
    padding: 0.6vh 1.5vw;
    font-weight: 600;
    border-radius: 5px;
    font-size: 0.9rem;
    box-shadow: 1px 1px 4px var(--tertiary-color);
    transition: 0.4s ease-in-out;
    color: var(--primary-text-color);
    margin: 0;
}
.header-principal button:hover{
    background-color: var(--primary-color-2);
}
.header-principal .hover{
    transition: 0.3s ease-in-out;
}
.header-principal .hover:hover{
    color: var(--primary-color);
}

.header-principal .buttons-container {
    display: flex;
    grid-gap: 20px;
    gap: 20px; ;
}

.header-principal .buttons-container a {
    margin: 0;
}




#header-mobile{
    font-size: 27px;
    margin-right: 3vw;
    cursor: pointer;
}
.dropdown-overlay div{
    width: 100%;
    height: 10vh;
    display: flex;
    justify-content: flex-end;
    padding-right: 6.5vw;
}
.dropdown-overlay div svg{
    font-size: 34px;
    height: 10vh;
}
.dropdown-overlay section{
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    width: 100%;
    height: 90vh;
    padding: 0 9vw 0 0;
    grid-gap: 2vh;
    gap: 2vh;
}
.dropdown-overlay section a{
    display: flex;
    align-items: center;
    grid-gap: 1.5vw;
    gap: 1.5vw;
    margin-bottom: 0.5vh;
}
.dropdown-overlay section button{
    background-color: var(--primary-color) !important;
    border: none;
    padding: 0.7vh 4vw;
    font-weight: 600;
    border-radius: 5px;
    font-size: var(--h6-font-size);
    box-shadow: 1px 1px 12px var(--tertiary-color);
    transition: 0.4s ease-in-out;
}
.dropdown-overlay {
    position: fixed !important;
    top: 0;
    right: -70%; 
    width: 70%;
    height: 100vh;
    background-color: var(--header-secundary-color);
    -webkit-backdrop-filter: blur(10px);
            backdrop-filter: blur(10px);
    display: flex;
    flex-direction: column;
    transition: right 0.3s ease-in-out;
    z-index: 9999;
}
.dropdown-overlay.open {
    right: 0;
}
.slide-in {
    -webkit-transform: translateX(0);
            transform: translateX(0);
}






/* header web */
@media screen and (min-width: 1081px){
    #header-web{
        display: flex;
    }
    #header-mobile{
        display: none;
    }
}
/* header mobile */
@media screen and (max-width: 1080px){
    #header-web{
        display: none;
    }
    #header-mobile{
        display: flex;
    }
    .header-principal img{
        width: 85px;
    }
    .header-principal button{
        background-color: var(--primary-color) !important;
    }
    .header-principal button:hover{
        background-color: var(--primary-color-2) !important;
    }
}
.body-planos{
    background-color: var(--secondary-color);
    height: 100%;
    display: flex;
    flex-direction: column;
    padding: 9% 15% 8% 15%;
    width: 100%;
    color: var(--primary-text-color);
}


.body-planos h1{
    text-align: center;
    font-size: 33px;
    font-weight: 700;
    margin-bottom: 2%;
}
.body-planos h2{
    text-align: center;
    font-size: 22px;
    margin-bottom: 8%;
}
.body-planos section{
    display: flex;
    justify-content: space-between;
}


.plano{
    width: 45%;
    background-color: var(--tertiary-color);
    transition: 0.3s ease-in-out;
    border-radius: 9px; 
    display: flex;
    flex-direction: column;
    box-shadow: 2px 2px 7px var(--tertiary-color);
    padding-bottom: 3%;
    color: var(--secondary-text-color);
}
.plano:hover{
    -webkit-transform: scale(1.05);
            transform: scale(1.05);
}
.plano h3{
    text-align: center;
    padding: 5% 2.5%;
    border-bottom: 1px solid var(--secondary-color);
    font-weight: 600;
    font-size: 22px;
}
.icon-plano{
    display: flex;
    justify-content: center;
    margin: 4% 0;
    color: var(--primary-color-4);
}
.icon-plano svg{
    font-size: 55px;
}
.plano h4, .plano h6{
    text-align: center;
    font-size: 12px;
    margin: 2% 0 0 0;
}
.plano h4{
    height: 70px;
}
.plano h4 span{
    font-size: 35px;
    font-weight: 700;
}
.plano button{
    background-color: var(--primary-color-3);
    color: var(--secondary-text-color);
    border: none;
    padding: 6px 7px;
    border-radius: 4px;
    width: 60%;
    margin: 5% 0 10% 20%;
    box-shadow: 1px 1px 6px var(--tertiary-color);
    transition: 0.4s ease-in-out;
    white-space: nowrap;
}
.plano button:hover{
    background-color: var(--primary-color);
}
.plano p{
    padding: 0 7%;
    margin-bottom: 4%;
}
.plano p svg{
    font-size: 23px;
    margin-right: 1%;
    color: var(--primary-color-3);
}






/* celular e tablet */
@media screen and (max-width: 1080px) {
    .body-planos{
        padding: 30% 7% 15% 7%;
    }
    .body-planos h1{
        font-size: 30px;
        margin-bottom: 5%;
    }
    .body-planos h2{
        font-size: 20px;
        margin-bottom: 14%;
    }
    .body-planos section{
        flex-direction: column;
        grid-gap: 3vh;
        gap: 3vh;
    }
    .plano{
        width: 100%;
        padding-bottom: 4%;
    }
    .icon-plano svg{
        font-size: 60px;
    }
    .plano button{
        box-shadow: 1px 1px 8px var(--tertiary-color);
    }
    .plano p{
        margin-bottom: 5%;
    }
    .plano p svg{
        font-size: 25px;
    }
}
.card-container {
    height: 410px;
    width: 300px;
    -webkit-perspective: 1000px;
            perspective: 1000px;
    margin: 0.5rem;
    position: relative;
    margin-bottom: 100px;
}

.card-container .card-front, 
.card-container .card-back {
    height: 100%;
    width: 100%;
    position: absolute;
    -webkit-backface-visibility: hidden;
            backface-visibility: hidden;
    -webkit-transform-style: preserve-3d;
            transform-style: preserve-3d;
    transition: -webkit-transform 0.6s ease;
    transition: transform 0.6s ease;
    transition: transform 0.6s ease, -webkit-transform 0.6s ease;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #9075B0;
    padding: 1rem;
    box-sizing: border-box;
}

.card-front {
    -webkit-transform: rotateY(0deg);
            transform: rotateY(0deg);
}

.card-back {
    -webkit-transform: rotateY(180deg);
            transform: rotateY(180deg);
}

.card-container.is-flipped .card-front {
    -webkit-transform: rotateY(180deg);
            transform: rotateY(180deg);
}

.card-container.is-flipped .card-back {
    -webkit-transform: rotateY(0deg);
            transform: rotateY(0deg);
}

.card-img {
    width: 50%;
}

.card-title {
    margin: 0.5rem 5%;
    text-align: center;
}

.toggle-images-btn {
    text-align: center;
    width: 90%;
    border-radius: 7px;
    padding: 0.5rem 1rem;
    background-color: #614a80;
    color: white;
    text-decoration: none;
    margin: 0.5rem;
    cursor: pointer;
}

.images-container {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 1rem;
    width: 100%;
    align-items: center;
}

.container-img-item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    grid-gap: 5px;
    gap: 5px;
}

.image-item {
    width: 40px;
    height: 40px;
}



.body-inicio{
    background-image: url(/static/media/fundo-login.820fcd01.png);
    background-size: cover;
    height: 100vh;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-around;
    color: var(--secondary-text-color);
}


.body-inicio section{
    width: 28%;
    display: flex;
    flex-direction: column;
    grid-gap: 12vh;
    gap: 12vh;
}
.logo-var{
    width: 100%;
}
.body-inicio section a{
    display: flex;
    align-items: center;
}
.body-inicio section a svg{
    font-size: 20px;
    margin-right: 0.6vw;
}
.body-inicio section a:hover{
    text-decoration: underline;
}


.container-login{
    background: linear-gradient(140deg, var(--primary-color) 2%, var(--primary-color-4) 83%);
    border-radius: 13px;
    padding: 20px;
    width: 39vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-right: 3%;
    box-sizing: content-box;
}
.container-login div label{
    font-size: 15px;
}
.container-login p{
    margin-bottom: 5%;
    
}
.logo-var-circulo{
    width: 18%;
    margin-bottom: 20px;
}
.botoes-login{
    display: flex;
    justify-content: space-around;
}
.botao-azul{
    background-color: var(--primary-color-3);
    color: var(--secondary-text-color);
    border: none;
    margin-top: 15px;
    margin-bottom: 5px;
    padding: 6px 7px;
    width: 42%;
    margin-right: 2%;
    height: 20%;
    border-radius: 9px;
    cursor: pointer;
    font-size: 13px;
    box-shadow: 1px 1px 6px var(--tertiary-color);
    transition: 0.4s ease-in-out;
}
.botao-azul a{
    color: var(--secondary-text-color);
    text-decoration: none;
}
.botao-azul:hover{
    background-color: var(--primary-color);
}
.container-input{
    display: flex;
    align-items: center;
    background-color: var(--secondary-color);
    border-radius: 8px;
    height: 37px;
    width: 100%;
    margin-right: 80px;
    margin-bottom: 5%;
    padding: 2px;
    box-sizing: content-box;
}
.container-input svg{
    font-size: 22px;
    color: var(--tertiary-color);
    margin-left: 10px;
    margin-right: 5px;
}
.container-input input{
    background: transparent;
    color: var(--primary-text-color);
    width: 85%;
    border: 0;
    margin-left: 8px;
    outline: 0;
    padding-top: 2px;
    font-size: 13px !important;
    font-weight: 500;
}

.container-login div span{
    font-size: 0.8rem;
    text-decoration: underline;
    cursor: pointer;
    margin-left: 1%;
}


.componente-redefinir-senha .p-redefinir{
    text-align: center;
}


.modal-redefinir-senha .modal-content{
    border-radius: 30px;
}

.modal-redefinir-senha .modal-body{
    background-color: var(--secondary-color);
    color: var(--primary-text-color);
    border-radius: 8px;
    padding: 12% 10%;
    display: flex;
    align-items: center;
    flex-direction: column;
}

.modal-redefinir-senha .modal-body h6{
    font-size: 1.1rem;
    font-weight: 700;
}

.modal-redefinir-senha .modal-body p{
    padding-top: 5%;
    font-size: 1rem;
    text-align: center;
}





.body-redefinir-senha{
    background-color: var(--secondary-color);
    height: 100vh;
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    color: var(--secondary-text-color);
}
.box-redefinir-senha{
    background-color: var(--primary-color-2);
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 28vw;
    height: 60vh;
    padding: 0 2%;
    border: 1px solid var(--border-gray-color);
    border-radius: 7px;
}
.box-redefinir-senha img{
    width: 20%;
    margin-bottom: 4%;
    margin-top: 10%;
}
.body-redefinir-senha section{
    width: 90%;
}
.body-redefinir-senha h1{
    font-size: 1.6rem;
    font-weight: 700;
    margin-bottom: 6%;
}
.body-redefinir-senha button{
    background-color: var(--green-color);
    color: var(--secondary-text-color);
    border: none;
    padding: 1.7% 10%;
    border-radius: 7px;
    cursor: pointer;
    font-size: 0.9rem;
    font-weight: 600;
    box-shadow: 1px 1px 8px var(--tertiary-color);
    transition: 0.4s ease-in-out;
}
.body-redefinir-senha .container-input{
    width: 100%;
    margin: 0 0 10% 0; 
}



@media screen and (max-width: 360px){
    .botao-azul{
        padding: 6px 3px;
        width: 48%;
        margin-right: 0;
        font-size: 11px;
    }
}


/* celular */
@media screen and (max-width: 700px){
    .body-inicio section{
        width: 45%;
    }
    .logo-var{
        margin-top: -40%;
        margin-bottom: 30%;
    }
    .body-inicio section a{
        display: none;
    }
    .logo-var-circulo{
        width: 50px;
    }
    .body-inicio{
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
    .container-login{
        border-radius: 10px;
        padding: 15px;
        width: 70vw;
        margin-right: 0;
    }
    .modal-redefinir-senha .modal-content{
        background-color: transparent;
        width: 80%;
        margin-left: 10%;
    }
    .modal-redefinir-senha .modal-body{
        padding: 12% 15%;
    }
    .modal-redefinir-senha .modal-body h6{
        text-align: center;
    }
    .box-redefinir-senha{
        width: 85vw;
        height: 54vh;
    }
}





/* tablet */
@media screen and (min-width: 701px) and (max-width: 1080px){
    .body-inicio section{
        width: 45%;
    }
    .logo-var{
        margin-top: -40%;
        margin-bottom: 30%;
    }
    .body-inicio section a{
        display: none;
    }
    .body-inicio{
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
    .container-login{
        width: 50vw;
        margin-right: 0;
        padding: 25px 0;
    }
    .logo-var-circulo{
        width: 49px;
    }
    .componente-redefinir-senha .p-redefinir{
        width: 80%;
    }
    .modal-redefinir-senha .modal-content{
        background-color: transparent;
    }
    .modal-redefinir-senha .modal-body h6{
        font-size: 1.2rem;
    }
    .box-redefinir-senha{
        width: 50vw;
        height: 46vh;
    }
}
.body-cadastro .logo-var{
   margin-left: 7.7%;
   width: 28%;
}

.container-cadastro{
    margin-right: 3%;
    margin-left: 15.5%;
}
.cadastro-icon{
    background-color: var(--white-color);
    padding: 12px;
    width: 67px;
    border-radius: 50%;
    box-shadow: -2px 2px 20px var(--black-color);
    position: absolute;
    top: 11%;
    right: 14%;
    box-sizing: content-box;
}
.cadastro-icon svg{
    font-size: 65px;
    color: var(--4-gray-color);
} */
.container-cadastro p{
    padding-right: 5%;
}

.aalala{
    display: block;
    position: absolute;
    margin: auto;
    margin-right: auto;
}
.aalala div{
    display: block;    
}




.body-confirmacao{
    background-color: var(--secondary-color);
    height: 100vh;
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    color: var(--primary-text-color);
    text-align: center;
}
.body-confirmacao div{
    background-color: var(--primary-color-2);
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 28%;
    height: 60%;
    padding: 0 2%;
    border: 1px solid var(--border-gray-color);
    border-radius: 7px;
}
.body-confirmacao img{
    width: 22%;
    margin-bottom: 9%;
    margin-top: 20%;
}
.body-confirmacao h1{
    font-size: 1.6rem;
    font-weight: 700;
    margin-bottom: 5%;
}
.body-confirmacao button{
    background-color: var(--green-color);
    color: #fff;
    border: none;
    padding: 1.7% 10%;
    border-radius: 7px;
    cursor: pointer;
    font-size: 0.9rem;
    font-weight: 600;
    box-shadow: 1px 1px 7px var(--tertiary-color);
    transition: 0.4s ease-in-out;
    margin-top: 5%;
}
.body-confirmacao button:hover{
    background-color: var(--2-green-color);
}
.span-login{
    display: flex;
    justify-content: space-between !important;
}
.span-login p{
    font-size: 0.8rem;
    width: 60%;
    padding-right: 0;
    margin-top: 8px;
    align-items: center;
    flex-direction: column;
    display: flex;
}



.modal-parabens .botao-cadastro{
    width: 100%;
    height: 63%;
    margin-right: 40px;
    color: var(--white-color);
}

.modal-parabens .modal-content{
    border-radius: 30px;
}

.modal-parabens .modal-body{
    background-color: var(--secondary-color) !important;
    border-radius: 8px;
    padding: 12% 7%;
    display: flex;
    align-items: center;
    flex-direction: column;
    color: var(--primary-text-color);
}

.modal-parabens .modal-body img{
    width: 15% !important;
    margin-bottom: 5%;
}

.modal-parabens .modal-body h2{
    font-size: 1.5rem;
    font-weight: 700;
    text-align: center;
}

.modal-parabens .modal-body h6{
    font-size: 1.1rem;
    font-weight: 700;
}

.modal-parabens .modal-body p{
    padding-top: 5%;
    font-size: 1rem;
    text-align: center;
}

.modal-parabens .modal-body p a{
    text-decoration: underline;
    color: var(--primary-color);
}



/* celular e tablet */
@media screen and (max-width: 1080px){
    .container-cadastro {
        margin-right: 0%;
        margin-left: 0%;
    }
    .body-cadastro .logo-var {
        margin-left: -5%;
    }
    .cadastro-icon{
        display: none;
    }
    .cadastro-icon{
        padding: 12px;
        width: 58px;
        border-radius: 50%;
        top: 22%;
        right: 9%;
    }
    .cadastro-icon svg{
        font-size: 58px;
    } */
    .body-confirmacao{
        padding: 0 10%;
        text-align: center;
    }
    .body-confirmacao button{
        padding: 2% 5%;
        font-size: 1rem;
    }
    .body-confirmacao div{
        width: 100%;
        height: 45%;
    }
    .body-confirmacao h1{
        width: 75%;
    }
    .modal-parabens .modal-content{
        width: 80%;
        margin-left: 10%;
    }
    .modal-parabens .modal-body h2{
        font-size: 1.5rem;
        font-weight: 700;
        text-align: center;
    }
    .modal-parabens .modal-body h6{
        text-align: center;
    }
    .body-cadastro .modal-parabens .modal-body img{
        width: 25%;
        margin-bottom: 5%;
    }
}
.modal-suporte .modal-content{
    border-radius: 20px;
    background-color: transparent;
    border: none;
    height: 75vh;
}
.modal-suporte .modal-header{
    background-color: var(--secondary-color);
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    color: var(--primary-text-color);
    padding-right: 3%;
    padding-left: 3%;
}
.modal-suporte .modal-header svg{
    font-size: 30px;
    margin-right: 1vw;
}
.modal-suporte .modal-body{
    background-color: var(--secondary-color);
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    display: flex;
    flex-direction: column;
    color: var(--primary-text-color) !important;
    padding: 4% 5% 7% 5%;
}

.modal-suporte .modal-body h2{
    font-size: 23px;
    font-weight: 600;
    text-align: center;
}
.modal-suporte .modal-body a{
    text-align: center;
}
.modal-suporte .modal-body p{
    margin-bottom: 0;
    text-align: center;
}
.modal-suporte .modal-body p span{
    text-decoration: underline;
}
.modal-suporte .modal-body div{
    height: 100%;
    width: 100%;
    text-align: center;
    margin: 1% 0 5% 0;
}
.modal-suporte .modal-body iframe{
    height: 281px;
    width: 500px;
    border-radius: 6px;
}
.modal-suporte .botao-suporte{
    margin: 4% 0;
    padding: 8px 6%;
    background-color: var(--primary-color-3);
    border: none;
    font-weight: 600;
    border-radius: 5px;
    font-size: 0.9rem;
    box-shadow: 1px 1px 7px var(--tertiary-color);
    transition: 0.6s ease-in-out;
}
.modal-suporte .botao-suporte:hover{
    background-color: var(--primary-color);
}



/* celular e tablet */
@media screen and (max-width: 1080px){
    .modal-suporte .modal-content{
        height: 65vh;
    }
    .modal-suporte .modal-body{
        padding: 8% 5%;
    }
    .modal-suporte .modal-body iframe{
        height: 165px;
        width: 300px;
    }
}
.body-home-aluno{
  background-color: var(--secondary-color);
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  color: var(--primary-text-color);
  padding: 7% 0 6% 0;
}

.alerta-atraso{
  background-color: var(--light-orange-color) !important;
  color: var(--tertiary-color) !important;
}
.alerta-cancelamento{
  background-color: var(--dark-red-color) !important;
}
.alerta-pagamento{
  margin-bottom: 4vh;
  background-color: var(--primary-color-3);
  width: 45%;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  color: var(--primary-text-color);
  font-size: 14px;
  padding: 4px 30px;
  font-weight: 500;
  align-items: center;
  text-align: center;
}
.alert-icon{
  width: 19px;
  margin: 0 0 0.5vh 0.6vw !important;
}

.video-aviso{
  width: 600px;
  height: 338px;
  margin-bottom: 5%;
  border-radius: 6px;
}

.body-home-aluno img{
  margin: 0 0 40px 0;
}
.info-home{
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 60%;
}
.info-home p{
  margin-top: 3%;
  font-weight: 600;
}
.info-home p span{
  text-decoration: underline;
}
 
.box-cadastrado{
  width: 60%;
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-top: 0.5%;
}
.box-presencial, .box-online{
  background: linear-gradient(90deg, var(--primary-color-3) 0%, #000000 50%, #000000 45%, var(--primary-color-3) 100%);
  padding: 3% 3% 3% 3%;
  border-radius: 6px;
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  text-decoration: none;
  color: var(--secondary-color);
  transition: 0.2s ease-in;
}
.box-presencial div, .box-online div{
  width: 70%;
  display: flex;
  align-items: flex-end;
  flex-direction: column;
}
.box-presencial p, .box-online p{
  font-size: 20px;
  font-weight: 600;
  text-align: center;
  width: 28%;
  min-width: 28%;
  margin-bottom: 0;
}
.box-presencial span, .box-online span{
  font-size: 13px;
  width: 70%;
  text-align: end;
}
.box-presencial button, .box-online button{
  background-color: var(--secondary-color);
  color: var(--dark-blue-color);
  border: none;
  padding: 5px 20px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 15px;
  font-weight: 700;
  display: flex;
  align-items: center;
  margin: 7% 0 0 0;
}


.box1-home{
  background: linear-gradient(90deg, var(--primary-color-3) 0%, #000000 50%, #000000 45%, var(--primary-color-3) 100%);
  padding: 20px 0;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 60%;
  text-decoration: none;
  color: var(--secondary-text-color);
  transition: 0.2s ease-in;
}
.box1-home:hover{
  -webkit-transform: scale(1.01);
          transform: scale(1.01);
}
.box1-home p{
  margin: 0 25px 0 25px;
  display: flex;
  align-items: center;
  cursor: pointer;
}
.box1-home svg:nth-child(1){
  font-size: 35px;
  margin-right: 10px;
}
  
  
.home-section1{
  margin-top: 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 60%;
  height: 220px;
}
.box-da-trilha{
  width: 49%;
  height: 100%;
  border-radius: 6px;
  background-size: cover;
  text-decoration: none;
  color: var(--secondary-text-color);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.box-da-trilha h2{
  font-size: 16px;
  font-weight: 600;
  margin: 5% 0 0 5%;
}
.box-da-trilha p{
  font-size: 13px;
  width: 90%;
  margin: 3% 15% 3% 5%;
}
.box-da-trilha button{
  border-radius: 8px;
  font-size: 12px;
  padding: 1px 22px;
  height: 55%;
  margin: 6px 0 30px 5%;
  color: var(--secoundary-text-color);
  background-color: var(--primary-color-3);
  font-weight: 600;
}
.box-da-trilha button:hover{
  background-color: var(--primary-color);
}
.box-csharp{
  background-image: url(/static/media/codefundo3.eb360561.png);
}
.box-java{
  background-image: url(/static/media/codefundo1.e85cfad9.png);
}
  
  
.home-section2{
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 60%;
  margin-top: 30px;
  color: var(--secondary-text-color);
}
.box-do-add{
  border-radius: 6px;
  width: 49%;
  background-size: cover;
}
.box-do-add h2{
  font-size: 16px;
  margin: 3% 0 0 5%;
}
.box-do-add svg:nth-child(1){
  font-size: 15px;
  margin-right: 5px;
}
.box-do-add a{
  text-decoration: none;
}
.botao-branco{
  background-color: var(--secondary-color);
  color: var(--primary-color-4);
  border: none;
  margin: 15px 0 5% 5%;
  padding: 5px 20px;
  border-radius: 8px;
  cursor: pointer;
  font-size: 14px;
  font-weight: 700;
  display: flex;
  align-items: center;
}
.home-section2 .box-linkedin{
  background-image: url(/static/media/codefundo2.92dca374.png);
}
.home-section2 .box-perfil{
  background-image: url(/static/media/codefundo2.92dca374.png);
}



.modal-atraso .modal-body{
  background-color: var(--secondary-color);
  border-radius: 8px;
  padding: 8% 10% 10% 10%;
  display: flex;
  align-items: center;
  flex-direction: column;
  color: var(--primary-text-color);
}
.modal-atraso .modal-body img{
  width: 18%;
  margin-bottom: 5%;
}
.modal-atraso .modal-body h6{
  font-size: 1.1rem;
  font-weight: 700;
}
.modal-atraso .modal-body p{
  padding-top: 1%;
  font-size: 1rem;
  text-align: center;
  width: 90%;
}






/* celular */
@media screen and (max-width: 767px){
  .alerta-pagamento{
    margin-bottom: 2vh;
    width: 90%;
    text-align: center;
  }
  .alerta-atraso img{
    display: none;
  }
  .video-aviso{
    width: 350px;
    height: 200px;
  }
  .body-home-aluno{
    padding: 30% 0 10% 0;
  }
  .body-home-aluno img{
    margin: 0 0 35px 0;
  }
  .info-home{
    width: 90%;
    text-align: start;
    flex-direction: column;
  }
  .box-cadastrado{
    border-radius: 5px;
    width: 90%;
  }
  .box-cadastrado .box-presencial, .box-online{
    width: 100% !important;
    display: flex;
    flex-direction: column;
    padding-top: 5%;
    padding: 5% 0 7% 0;
  }
  .box-presencial p, .box-online p{
    width: 100%;
    min-width: 100%;
  }
  .box-presencial div, .box-online div{
    width: 80%;
    align-items: center;
  }
  .box-presencial span, .box-online span{
    width: 100%;
    text-align: center;
    margin: 2% 0;
  }
  .box1-home{
    border-radius: 5px;
    width: 90%;
  }
  .box1-home p{
    margin: 0 10px;
    font-size: 14px;
  }
  .box1-home svg:nth-child(1){
    font-size: 40px;
    margin-right: 6px;
  }
  .home-section1{
    flex-direction: column;
    width: 90%;
    height: 100%;
  }
  .home-section1 .box-da-trilha:first-child{
    margin-bottom: 7%;
  }
  .box-da-trilha{
    width: 100%;
    height: 100%;
  }
  .box-da-trilha h2{
    font-size: 16px;
    font-weight: 600;
  }
  .box-da-trilha p{
    font-size: 14px;
  }
  .box-da-trilha button{
    border-radius: 6px;
    font-size: 14px;
    padding: 6px 10px;
  }
  .home-section2{
    flex-direction: column;
    width: 90%;
  }
  .home-section2 .box-do-add:first-child{
    margin-bottom: 7%;
  }
  .box-do-add{
    width: 100%;
  }
  .box-do-add h2{
    font-weight: 600;
  }
  .box-do-add svg:nth-child(1){
    font-size: 16px;
  }
  .botao-branco{
    padding: 5px 15px;
    border-radius: 6px;
  }
}




/* tablet */
@media screen and (min-width: 768px) and (max-width: 1080px){
  .alerta-pagamento{
    margin-bottom: 2vh;
    width: 90%;
    text-align: center;
  }
  .alerta-atraso img{
    display: none;
  }
  .video-aviso{
    width: 350px;
    height: 200px;
  }
  .body-home-aluno{
    padding: 18% 0 10% 0;
  }
  .body-home-aluno img{
    margin: 0 0 35px 0;
  }
  .info-home{
    width: 85%;
    text-align: start;
    flex-direction: column;
  }
  
  .box-cadastrado{
    border-radius: 5px;
    width: 90%;
  }
  .box-cadastrado .box-presencial, .box-online{
    width: 100% !important;
    display: flex;
    flex-direction: column;
    padding-top: 5%;
    padding: 5% 0 7% 0;
  }
  .box-presencial p, .box-online p{
    width: 100%;
    min-width: 100%;
  }
  .box-presencial div, .box-online div{
    width: 80%;
    align-items: center;
  }
  .box-presencial span, .box-online span{
    width: 100%;
    text-align: center;
    margin: 2% 0;
  }
  .box1-home{
    border-radius: 5px;
    width: 85%;
  }
  .box1-home p{
    margin: 0 10px;
    font-size: 15px;
  }
  .box1-home svg:nth-child(1){
    font-size: 40px;
    margin-right: 9px;
  }
  .home-section1{
    width: 85%;
    height: 100%;
  }
  .box-da-trilha{
    width: 47%;
    height: 100%;
  }
  .box-da-trilha h2{
    font-size: 17px;
    font-weight: 600;
  }
  .box-da-trilha p{
    font-size: 14px;
  }
  .box-da-trilha button{
    border-radius: 6px;
    font-size: 14px;
    padding: 6px 9px;
  }
  .home-section2{
    width: 85%;
  }
  .box-do-add{
    width: 47%;
  }
  .box-do-add h2{
    font-size: 17px;
    font-weight: 600;
  }
  .box-do-add svg:nth-child(1){
    font-size: 16px;
  }
  .botao-branco{
    padding: 5px 15px;
    border-radius: 6px;
  }
}
.active {
    color: var(--primary-color);
}

.header-aluno{
    background-color: var(--header-secundary-color);
    padding: 1% 4%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: fixed;
    z-index: 5;
    width: 100%;
    height: 10vh;
    color: var(--primary-text-color);
}
.header-aluno img{
    width: 52%;
}
.header-aluno section{
    display: flex;
    align-items: center;
    white-space: nowrap;
    grid-gap: 1vw;
    gap: 1vw;
    font-size: 17px;
    font-weight: 500;
    margin-left: -2vw;
}
.header-aluno section a{
    transition: 0.2s ease-in;
    font-weight: 600;
}
.header-aluno section a svg{
    margin-left: 5px;
    font-size: 14px;
}
.header-aluno .btn-adm button{
    background-color: var(--primary-color);
    border: none;
    font-size: 16px;
}
.header-aluno .btn-adm ul li a{
    color: var(--primary-text-color);
    font-size: 16px;
}
.header-aluno section a:hover{
    color: var(--primary-color);
}
.header-aluno .btn-azul{
    background-color: var(--primary-color);
    border: none;
    padding: 0.6vh 1.5vw;
    font-weight: 600;
    border-radius: 5px;
    font-size: 0.8rem;
    box-shadow: 1px 1px 8px var(--tertiary-color);
    transition: 0.3s ease-in-out;
}
.header-aluno .btn-azul:hover{
    background-color: var(--primary-color-2);
}
.header-aluno .dropdown-menu{
    background-color: var(--header-secundary-color);
}
.header-aluno .dropdown-item{
    width: 100%;
    justify-content: start;
}
.header-aluno .dropdown-item:hover{
    color: var(--primary-color);
}
.ultima-section{
    display: flex;
    align-items: center;
    grid-gap: 1.2vw;
    gap: 1.2vw;
}
.ultima-section a:first-child{
    font-weight: 600;
}
.ultima-section img{
    width: 47px;
    min-width: 47px;
    max-width: 47px;
    height: 47px;
    min-height: 47px;
    max-height: 47px;
    border-radius: 50%;
    border: 2px solid var(--tertiary-color);
}
.ultima-section button{
    font-weight: 700 !important;
}




#header-mobile{
    font-size: 27px;
    margin-right: 3vw;
    cursor: pointer;
    color: var(--tertiary-color);
}
.dropdown-overlay div{
    width: 100%;
    height: 10vh;
    display: flex;
    justify-content: flex-end;
    padding-right: 6.5vw;
    background-color: var(--header-secundary-color);
}
.dropdown-overlay div svg{
    font-size: 34px;
    height: 10vh;
}
.dropdown-overlay section{
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    width: 100%;
    height: 90vh;
    grid-gap: 2vh;
    gap: 2vh;
    background-color: var(--header-secundary-color);
    margin-left: 0;
}
.header-aluno .dropdown-overlay section{
    padding: 0 9vw 0 0 !important;
}
.header-aluno .dropdown-overlay section img{
    width: 100px;
    min-width: 100px;
    max-width: 100px;
    height: 100px;
    min-height: 100px;
    max-height: 100px;
    border-radius: 30%;
    border: 2px solid var(--tertiary-color);
}
.header-aluno .dropdown-overlay section p{
    font-size: 13px;
    color: var(--primary-color);
    margin-bottom: 0;
}
.header-aluno .dropdown-overlay section button{
    padding: 0.7vh 5vw;
}
.dropdown-overlay section button{
    background-color: var(--primary-color);
    border: none;
    padding: 0.7vh 4vw;
    font-weight: 600;
    border-radius: 5px;
    font-size: var(--h6-font-size);
    box-shadow: 1px 1px 8px var(--tertiary-color);
    transition: 0.4s ease-in-out;
}
.dropdown-overlay section svg{
    font-size: 18px !important;
}
.dropdown-overlay {
    position: fixed !important;
    top: 0;
    right: -70%; 
    width: 70%;
    height: 100vh;
    -webkit-backdrop-filter: blur(10px);
            backdrop-filter: blur(10px);
    display: flex;
    flex-direction: column;
    transition: right 0.3s ease-in-out;
    z-index: 9999;
}
.dropdown-overlay.open {
    right: 0;
}
.slide-in {
    -webkit-transform: translateX(0);
            transform: translateX(0);
}






/* header web */
@media screen and (min-width: 1081px){
    #header-web{
        display: flex;
    }
    #header-mobile{
        display: none;
    }
}
/* header mobile */
@media screen and (max-width: 1080px){
    #header-web{
        display: none;
    }
    #header-mobile{
        display: flex;
    }
    .header-aluno img{
        width: 120px;
    }
    .btn-adm {
        height: 6vh !important;
        padding-right: 0 !important;
    }
}
.body-home-aluno .carousel-control-prev, .body-home-aluno .carousel-control-next {
  display: none !important;
}
.carousel-control-prev-icon {
  position: absolute;
  left: -40px;
}
.carousel-control-next-icon {
  position: absolute;
  right: -40px;
}

.carousel-inner{
  width: 80% !important;
  margin-left: 10%;
}

.carousel-inner div img{
  height: auto;
}

.fade-carousel .carousel-item {
  transition-property: opacity;
  opacity: 0;
}
.fade-carousel .carousel-item.active {
  transition-property: opacity;
  opacity: 1;
}
.fade-carousel .carousel-item {
  transition-property: opacity;
  opacity: 0;
  transition-duration: 1s; /* duração da transição de fade */
}
.fade-carousel .carousel-item.active {
  transition-property: opacity;
  opacity: 1;
  transition-duration: 1s; /* duração da transição de fade */
}





/* celular */
@media screen and (max-width: 767px){
  .carossel-home-aluno{
    width: 90% !important;
    margin-left: 5%;
  }
  .carossel-home-aluno div img{
    border-radius: 6px;
  }
}


/* tablet */
@media screen and (min-width: 768px) and (max-width: 1080px){
  .carossel-home-aluno{
    width: 85% !important;
    margin-left: 7.5%;
  }
}
.modal-perguntas .modal-content{
    background-color: var(--secondary-color);
    border-radius: 10px;
    border: none;
    height: 50vh;
    color: var(--tertiary-color);
}
.modal-perguntas .modal-header{
    border-color: var(--tertiary-color);
}
.modal-perguntas .modal-footer{
    border-color: var(--tertiary-color);
}
.modal-perguntas .modal-header p{
    margin-bottom: 0;
    font-size: 19px;
}
.modal-perguntas .modal-body{
    padding-top: 5%;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: var(--tertiary-color);
}
.modal-perguntas .modal-body p{
    font-size: 18px;
    width: 80%;
    text-align: center;
    margin-bottom: 4%;
}
.modal-perguntas .star{
    font-size: 38px !important;
    color: var(--dark-orange-color);
    cursor: pointer;
    margin: 0 0.3vw;
}
.filled{
    color: var(--dark-orange-color) !important;
}
.body-curso-online{
    background-color: var(--secondary-color);
    height: 100%;
    display: flex;
    flex-direction: column;
    color: var(--primary-text-color);
}


.header-online{
    height: 10vh;
    display: flex;
    align-items: center;
    background-color: var(--header-secundary-color);
    padding: 0 4%;
    width: 100%;
    position: fixed;
    z-index: 2;
}
.header-online img{
    width: 100px;
}
.header-online #header-web{
    width: 60%;
    grid-gap: 2%;
    gap: 2%;
    margin: 0 0 0 3vw;
}


.body-curso-online .textos{
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: 17vh 0 4% 0;
}
.body-curso-online .textos h1{
    font-size: 28px;
    font-weight: 600;
    margin-bottom: 2%;
    width: 70%;
}
.body-curso-online .textos h1 span{
    color: var(--primary-color-3);
}
.body-curso-online .textos p{
    width: 57%;
    color: var(--primary-text-color);
    font-size: 18px;
}
.body-curso-online .adquirir-curso{
    margin-top: 1.5%;
    background-color: var(--primary-color-3);
    color: var(--secondary-text-color);
    border: none;
    padding: 0.8% 4%;
    border-radius: 7px;
    cursor: pointer;
    font-size: 18px;
    font-weight: 700;
    box-shadow: 1px 1px 6px var(--tertiary-color);
    transition: 0.5s ease-in-out;
    outline: none;
}
.body-curso-online .adquirir-curso:hover{
    background-color: var(--primary-color);
}
.body-curso-online .adquirir-curso button{
    background-color: transparent;
    border: none;
}


.video-curso{
    padding: 0 0 5% 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-bottom: 1px solid;
    border-color: var(--tertiary-color);
}
.video-curso h3{
    font-size: 24px;
    font-weight: 600;
    margin-bottom: 2%;
}
.body-curso-online iframe{
    border-radius: 8px;
    width: 860px;
    height: 484px;
}


.oque-aprender{
    padding: 5% 7% 6% 7%;
    border-bottom: 1px solid;
    border-color: var(--tertiary-color);
    background-color: var(--primary-color-3);
    display: flex;
    align-items: center;
    justify-content: center;
}
.oque-aprender ul{
    list-style: none;
    border: 1px solid var(--tertiary-color);
    border-radius: 6px;
    width: 80%;
    padding: 3% 5%;
    flex-wrap: wrap;
    display: flex;
    justify-content: space-between;
    margin-bottom: 0;
}
.oque-aprender ul h3{
    width: 100%;
    font-size: 24px;
    font-weight: 600;
    margin-bottom: 4%;
    color: var(--primary-text-color);
    text-align: center;
}
.oque-aprender ul li{
    width: 48%;
    margin-bottom: 2%;
    color: var(--primary-text-color);
}
.oque-aprender ul li svg{
    font-size: 27px;
    margin-right: 1.5%;
    color: var(--tertiary-color);
}


.porque-var{
    padding: 5% 7% 2% 7%;
    border-bottom: 1px solid;
    border-color: var(--tertiary-color);
    background-color: var(--secondary-color);
}
.porque-var h3{
    text-align: center;
    font-size: 24px;
    font-weight: 600;
}
.blocos-web{
    display: flex !important;
    justify-content: space-between !important;
    flex-direction: column !important;
    grid-gap: 4% !important;
    gap: 4% !important;
    width: 100%;
    margin: 3% 0;
}
.blocos-web div{
    cursor: default;
    border-radius: 9px;
    color: var(--primary-text-color);
}
.blocos-web div h6 svg{
    font-size: 27px;
    width: 30%;
    color: var(--tertiary-color);
}
.blocos-web div h6{
    font-size: 18px;
    font-weight: 600;
    display: flex;
    align-items: center;
    padding: 4% 5% 0 0;
    height: 65px;
    margin-right: 15px;
    margin-bottom: 0;
    background-color: var(--primary-color-4);
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
}
.blocos-web div p{
    font-size: 14px;
    padding: 3% 3% 0 4%;
    background-color: var(--primary-color-4);
    height: 120px;
    margin-right: 15px;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
}






.body-curso-online .curso{
    padding: 5% 7%;
    border-bottom: 1px solid;
    text-align: center;
    border-color: var(--tertiary-color);
    background-color: var(--primary-color-4);
    color: var(--secondary-text-color);
}
.body-curso-online .curso h1{
    font-size: 28px;
    font-weight: 600;
}
.body-curso-online .curso p{
    padding: 0 15%;
}
.body-curso-online .curso .p-2{
    margin-bottom: 3%;
}
.body-curso-online .curso h3{
    font-size: 18px;
    font-weight: 700;
    margin-bottom: 4%;
}


.detalhes-curso{
    padding: 5% 7%;
    border-bottom: 1px solid;
    border-color: var(--tertiary-color);
}
.detalhes-curso h3{
    font-size: 24px;
    font-weight: 600;
    text-align: center;
}  
.detalhes-p{
    margin-bottom: 2%;
    text-align: center;
    padding: 0 15%;
}
.curso-online-container3{
    padding: 3% 0 5% 0;
    display: flex;
    flex-direction: column;
    width: 100%;
}
.curso-online-container3 h3{
    font-weight: 600;
    margin-bottom: 3%;
    font-size: 24px;
}
.curso-online-container3 p, .curso-online-container3 .accordion-online{
    margin-left: 27%;
}


.accordion-online{
    width: 45%;
    max-height: 55vh;
    border: 1px solid var(--tertiary-color);
    margin-bottom: 2%;
}
.accordion-online .botao2{
    background-color: var(--primary-color-4) !important;
}
.accordion-online-modulos{
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 0 !important;
}
.accordion-online-modulos .botao1{
    background-color: var(--secondary-color) !important;
}
.accordion-online-modulos .div1{
    width: 100%;
}
.accordion-online-modulos ul{
    list-style: none;
    width: 100%;
    padding: 0;
    background-color: var(--secondary-color);
}
.accordion-online-modulos li{
    display: flex;
    align-items: center;
    padding: 3% 3%;
    border-bottom: 1px solid;
    border-color: var(--tertiary-color);
    justify-content: space-between;
    color: var(--primary-text-color);
}
.accordion-online-modulos li div{
    display: flex;
    align-items: center;
    justify-content: start;
    width: 90%;
}
.accordion-online-modulos li div svg{
    width: 20px;
    min-width: 20px;
    font-size: 18px;
    margin-right: 1vw;
    margin-top: -1px;
    color: var(--primary-color-3);
}
.accordion-online-modulos li span{
    color: var(--primary-text-color);
    font-size: 0.8rem;
    font-weight: 500;
}
.accordion-online-aulas{
    padding: 0 !important;
}


.quanto-ganha{
    margin-top: 4%;
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 0 20%;
}
.quanto-ganha p{
    margin-bottom: 0;
}
.quanto-ganha h6{
    margin-bottom: 0;
}
.quanto-ganha span{
    border-top: 3px solid var(--primary-color);
    font-size: 8px;
    padding: 0 7.5vw;
    color: transparent;
}
.quanto-ganha div:nth-child(2){
    text-align: center;
}
.quanto-ganha div:nth-child(3){
    text-align: end;
}


.professores{
    padding: 5% 7% 0 7%;
    border-bottom: 1px solid;
    border-color: var(--tertiary-color);
    background-color: var(--tertiary-color);
    color: var(--secondary-text-color);
}
.professores h3{
    text-align: center;
    font-size: 29px;
    font-weight: 600;
}
.professores .perfis{
    padding: 4% 0 5% 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.professores .perfis .perfil{
    width: 29%;
}
.professores .perfil section{
    display: flex;
    align-items: center;
}
.professores .perfil img{
    width: 90px;
    border-radius: 50%;
    margin-bottom: 5%;
}
.professores .perfil section div{
    padding-left: 5%;
    margin-top: -20px;
    width: 100%;
}
.professores .perfil section div p{
    margin-bottom: 0;
    font-weight: 600;
    font-size: 19px;
    text-align: justify;
}
.professores .perfil p{
    text-align: justify;
}
.professores .perfil section div span{
    font-size: 15px;
    color: var(--primary-color);
    font-weight: 500;
}




/* celular e tablet */
@media screen and (max-width: 1080px) {
    .header-online{
        padding: 0 6%;
        justify-content: space-between;
    }
    .header-online img{
        width: 70px;
    }
    .header-online svg{
        font-size: 27px;
        cursor: pointer;
    }
    .body-curso-online .textos{
        padding: 15vh 5% 9% 5%;
    }
    .body-curso-online .textos h1{
        font-size: 23px;
        margin-bottom: 4%;
        width: 100%;
    }
    .body-curso-online .textos p{
        width: 100%;
    }
    .body-curso-online .adquirir-curso{
        margin-top: 3%;
        padding: 3% 15%;
        font-size: 14px;
    }
    .video-curso{
        padding: 5% 0 6% 0;
    }
    .video-curso h3{
        font-size: 20px;
        margin-bottom: 4%;
    }
    .body-curso-online iframe{
        border-radius: 8px;
        width: 350px;
        height: 198px;
    }
    .oque-aprender{
        padding: 10% 7%;
    }
    .oque-aprender ul{
        border-radius: 5px;
        width: 100%;
        padding: 5%;
    }
    .oque-aprender ul h3{
        font-size: 20px;
        margin-bottom: 5%;
    }
    .oque-aprender ul li{
        width: 100%;
        margin-bottom: 4%;
    }
    .oque-aprender ul li svg{
        font-size: 25px;
        margin-right: 2%;
    }
    .slick-prev:before {
        display: none;
    }
    .slick-next:before {
        display: none;
    }
    .porque-var{
        padding: 10% 7%;
    }
    .porque-var h3{
        margin-bottom: 6%;
        font-size: 20px;
    }
    .carousel-container {
        width: 100%;
        max-width: 100%;
        margin: 0 auto;
    }
    .slick-center .carousel-item {
        -webkit-transform: scale(1.1);
                transform: scale(1.1);
        transition: background-color 0.3s, -webkit-transform 0.3s;
        transition: background-color 0.3s, transform 0.3s;
        transition: background-color 0.3s, transform 0.3s, -webkit-transform 0.3s;
        width: 100vw !important;
        height: 22vh;
        /* background-color: #1e1e21;
        box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.511); */
    }
    /* .slick-dots {
        margin-bottom: 3% !important;
    } */
    .porque-var .blocos{
        width: 100%;
    }
    .porque-var .blocos div{
        width: 100%;
        display: flex;
        padding: 1% 2% 0% 2.5%;
        border-radius: 9px;
        max-height: 40vh;
        margin: 0 6px 1% 6px;
    }
    .porque-var .blocos div h6{
        font-size: 18px;
        font-weight: 600;
        display: flex;
        align-items: center;
        padding: 7% 0 0 0;
        cursor: grab;
        width: 50vw;
    }
    .porque-var svg{
        font-size: 26px;
        width: 25%;
        margin-right: 2.5vw;
        color: var(--tertiary-color);
        padding: 0 0 0 4%;
        cursor: grab;
    }
    .porque-var .blocos div p{
        font-size: 14px;
        width: 50vw;
        padding: 2% 3% 0 4%;
        cursor: grab;
    }





    .body-curso-online .curso{
        padding: 10% 7%;
    }
    .body-curso-online .curso h1{
        font-size: 23px;
    }
    .body-curso-online .curso p{
        padding: 0 0;
    }
    .body-curso-online .curso .p-2{
        margin-bottom: 8%;
    }
    .body-curso-online .curso h3{
        font-size: 16px;
        margin-bottom: 8%;
    }
    .detalhes-curso{
        padding: 10% 7% 7% 7%;
    }
    .detalhes-curso h3{
        font-size: 20px;
        text-align: start;
    }
    .detalhes-p{
        width: 100%;
        margin-bottom: 6%;
        text-align: justify;
        padding: 0 0;
    }
    .curso-online-container3{
        padding: 5% 0;
    }
    .curso-online-container3 h3{
        margin-bottom: 2%;
        font-size: 20px;
    }
    .curso-online-container3 p, .curso-online-container3 .accordion-online{
        margin-left: 0;
    }
    .accordion-online{
        width: 100%;
        margin-bottom: 7%;
        max-height: 45vh;
    }
    .accordion-online-modulos li div svg{
        margin-right: 2vw;
    }
    .quanto-ganha{
        margin-top: 7%;
        padding: 0 0;
    }
    .quanto-ganha div{
        margin-bottom: 2%;
    }
    .quanto-ganha span{
        border-top: 2px solid var(--primary-color);
        padding: 0 18vw;
    }
    .professores{
        padding: 10% 7% 2% 7%;
    }
    .professores h3{
        font-size: 23px;
    }
    .professores .perfis{
        padding: 6% 0;
        flex-direction: column;
    }
    .professores .perfil{
        margin-bottom: 8%;
        width: 100% !important;
    }
    .professores .perfil img{
        width: 80px;
    }
}
.body-inscricao-presencial {
    background-color: var(--secondary-color);
    height: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    color: var(--primary-text-color);
    padding: 9% 0 6% 0;
}

.body-inscricao-presencial .breadcrumb {
    width: 90vw;
    padding-left: 8%;
    padding-bottom: 2%;
    font-weight: 500;
}

.body-inscricao-presencial nav {
    --bs-breadcrumb-divider: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8'%3E%3Cpath d='M2.5 0L1 1.5 3.5 4 1 6.5 2.5 8l4-4-4-4z' fill='%236c757d'/%3E%3C/svg%3E");
}

.body-inscricao-presencial h1 {
    font-size: 2rem;
    font-weight: 600;
}

.body-inscricao-presencial .h2-1 {
    font-size: 1.3rem;
    font-weight: 600;
    margin-bottom: 1.5%;
    width: 60%;
}

.body-inscricao-presencial .h2-2 {
    font-size: 1.3rem;
    font-weight: 600;
    margin-bottom: 1.5%;
    width: 68%;
    margin-top: 4%;
}

.body-inscricao-presencial h3 {
    font-size: 1.2rem;
}

.container-mensalidade {
    display: flex;
    width: 85%;
    justify-content: center;
    margin: 4% 0 3% 14%;
    grid-gap: 4%;
    gap: 4%;
}

.container-mensalidade div .presencial-input svg {
    margin-right: 9px;
}

.container-mensalidade div {
    display: flex;
    flex-direction: column;
}

.container-mensalidade .mensalidade {
    width: 30%;
}

.container-mensalidade .mensalidade label svg {
    margin-left: 2%;
    margin-top: -7%;
    cursor: pointer;
    font-size: 18px;
}

.container-mensalidade .mensalidade div {
    display: flex;
    flex-direction: row !important;
    align-items: center;
    background-color: transparent;
}

.container-mensalidade .mensalidade div input {
    background: transparent;
    color: var(--primary-text-color);
    width: 100%;
    border: 0;
    outline: 0;
    font-size: 30px;
    font-weight: 800;
}

.mensalidade {
    position: relative;
}

.mensalidade .table-container {
    display: none;
    position: absolute;
    top: -155%;
    right: -8%;
    font-size: 12px;
}

/* .mensalidade .table-container table thead .titulos th {
    background-color: var(--5-gray-color) !important;
}

.mensalidade .table-container table tbody tr td {
    background-color: var(--4-gray-color) !important;
} */

.mensalidade:hover .table-container {
    display: block;
}


.container-turmas {
    width: 60%;
}

/* .table>:not(caption)>*>* {
    background-color: var();
} */
/* 
.table-striped>tbody>tr:nth-of-type(odd)>* {
    --bs-table-bg-type: ;
} */

.radio-turmas {
    margin-left: 47%;
    cursor: pointer;
    -webkit-transform: scale(1.2);
            transform: scale(1.2);
}


.container-dados {
    width: 70%;
}


.presencial-input {
    display: flex;
    flex-direction: row !important;
    align-items: center;
    background-color: transparent;
    color: var(--primary-text-color);
    border-radius: 6px;
    height: 2.3rem;
    width: 100%;
    margin-bottom: 8%;
    padding: 2px;
    border: 1px solid var(--tertiary-color);
}

.presencial-input svg {
    width: 10%;
    height: 58%;
    color: var(--tertiary-color);
    margin-left: 6px;
    margin-right: 8px;
}

.presencial-input input {
    background: transparent;
    color: var(--primary-text-color);    
    width: 90%;
    border: 0;
    outline: 0;
    padding-top: 3px;
    font-size: 14px;
}

.presencial-input select {
    background: transparent;
    color: var(--primary-text-color);
    width: 90%;
    border: 0;
    outline: 0;
    font-size: 14px;
    margin: 0;
}

.body-inscricao-presencial .porque {
    height: 18vh;
    outline: none;
    padding: 1%;
}

.porque-div {
    width: 101%;
    margin-left: -1.2%;
}

.presencial-botao {
    width: 70%;
    display: flex;
    justify-content: end;
    margin-top: -1%;
}

.botao-inscricao {
    background-color: var(--primary-color-3);
    color: var(--primary-text-color);
    border: none;
    padding: 0.6% 7px;
    width: 11%;
    border-radius: 7px;
    cursor: pointer;
    font-size: 16px;
    box-shadow: 1px 1px 7px var(--tertiary-color);
    transition: 0.3s ease-in-out;
    font-weight: 600;
}

.botao-inscricao:hover {
    background-color: var(--primary-color);
}






/* celular e tablet */
@media screen and (max-width: 1080px) {
    .body-inscricao-presencial {
        padding: 30% 0 8% 15%;
        width: 85% !important;
    }

    .body-inscricao-presencial h1 {
        font-size: 1.6rem;
        white-space: nowrap;
    }

    .body-inscricao-presencial .h2-1 {
        font-size: 1.2rem;
        margin-bottom: 3%;
        width: 120%;
    }

    .body-inscricao-presencial .h2-2 {
        font-size: 1.2rem;
        margin-bottom: 3%;
        width: 110%;
        margin-top: 5%;
    }

    .body-inscricao-presencial h3 {
        font-size: 1.1rem;
    }

    .container-mensalidade {
        flex-direction: column;
        margin: 10% 0%;
        grid-gap: 4%;
        gap: 4%;
        width: 100%;
    }

    .container-mensalidade .mensalidade {
        width: 100%;
        margin-left: 20%;
    }

    .container-mensalidade .mensalidade label svg {
        margin-left: 2%;
        margin-top: -5%;
    }

    .container-turmas {
        width: 80%;
    }

    .radio-turmas {
        margin-left: 42%;
        margin-top: 20%;
    }

    .container-dados {
        width: 100%;
    }

    .presencial-input {
        height: 2.5rem;
    }

    .botao-inscricao {
        width: 32%;
        border-radius: 5px;
    }

    .presencial-botao {
        width: 100%;
    }

    .porque-div {
        width: 104%;
        margin-left: -4%;
    }
}






.modal-parabens .botao-cadastro {
    width: 100%;
    height: 63%;
    margin-right: 40px;
    color: var(--primary-text-color);
}

.modal-parabens .modal-content {
    border-radius: 30px;
}

.modal-parabens .modal-body {
    background-color: var(--secondary-color);
    border-radius: 8px;
    padding: 12% 7%;
    display: flex;
    align-items: center;
    flex-direction: column;
    color: var(--primary-text-color) !important;
}

.modal-parabens .modal-body img {
    width: 20%;
    margin-bottom: 7%;
}

.modal-parabens .modal-body h2 {
    font-size: 1.5rem;
    font-weight: 700;
    text-align: center;
}

.modal-parabens .modal-body h6 {
    font-size: 1.1rem;
    font-weight: 700;
}

.modal-parabens .modal-body p {
    padding-top: 5%;
    font-size: 1rem;
    text-align: center;
}

.modal-parabens .modal-body p a {
    text-decoration: underline;
    color: var(--primary-color);
}
body {
    background-color: var(--secondary-color);
}

.article-trilha {
    padding: 9% 0 2% 0;
    background-color: var(--secondary-color);
}

.body-trilha {
    background-color: var(--secondary-color);
    height: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    color: var(--secondary-text-color);
    padding-bottom: 5%;
}

.box-trilha {
    background-color: #7561be;
    border-radius: 10px;
    height: 40vh;
    width: 80%;
    display: flex;
    transition: 0.4s ease-in-out;
    color: var(--secondary-text-color);
    text-decoration: none;
}

.box-trilha:hover {
    -webkit-transform: scale(1.03);
            transform: scale(1.03);
    z-index: 1;
    cursor: pointer;
}

.box-trilha img {
    width: 19%;
    height: 77%;
    margin: 30px 30px;
    margin-left: 30px;
    margin-right: 30px;
    border-radius: 10px;
}

.box-trilha-conteudo {
    margin-top: 30px;
    display: flex;
    flex-direction: column;
    max-width: 40%;
    min-width: 40%;
}

.box-trilha-conteudo h1 {
    font-size: 30px;
    font-weight: 600;
}

.box-trilha-conteudo p {
    max-width: 85%;
    font-size: 15px;
}

.box-trilha-box {
    margin-top: 30px;
    display: flex;
    flex-direction: column;
    max-width: 30%;
    min-width: 30%;
    background-color: var(--secondary-color);
    border-radius: 10px;
    padding: 20px 0 20px 20px;
    height: 80%;
    color: var(--primary-text-color);
    font-weight: 600;
}

.div-trilha-itens {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
}

.div-trilha-item {
    width: 50%;
    display: flex;
    margin: 10px 0;
    align-items: center;
}

.div-trilha-item svg {
    font-size: 25px;
    margin-right: 10px;
}

.div-trilha-item p {
    margin-bottom: 0;
    font-size: 13px;
}

.div-trilha-item span {
    font-size: 12px;
    font-weight: 600;
}

.div-progress {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
}

.div-progress span {
    margin-left: 10px;
    font-size: 11px;
    font-weight: 500;
}

.heigth-progress {
    height: 10px;
    width: 60%;
}

.loading {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    border-left:10px solid rgba(0, 0, 0, 0);
    border-right:10px solid rgba(0, 0, 0, 0);
    border-top: 10px solid var(--secondary-color);
    border-bottom: 10px solid var(--secondary-color);
    border-radius: 100%;
    width: 10vh;
    height: 10vh;
    -webkit-animation: rotacao linear infinite 0.5s;
            animation: rotacao linear infinite 0.5s;
}

@-webkit-keyframes rotacao{
    0%{
        rotate: 0deg;
    }
    100%{
        rotate: 360deg;
    }
}

@keyframes rotacao{
    0%{
        rotate: 0deg;
    }
    100%{
        rotate: 360deg;
    }
}







/* celular */
@media screen and (max-width: 767px){
    .article-trilha {
        padding: 30% 0 10% 0;
    }
    .box-trilha {
        border-radius: 8px;
        height: 40vh;
        width: 90%;
        flex-wrap: wrap;
    }
    .box-trilha img {
        width: 30%;
        height: 30%;
        margin: 20px 20px;
        border-radius: 8px;
    }
    .box-trilha-conteudo {
        margin-top: 20px;
        max-width: 55%;
        min-width: 55%;
    }
    .box-trilha-conteudo h1 {
        font-size: 19px;
        font-weight: 700;
    }
    .box-trilha-conteudo p{
        max-width: 95%;
        text-align: justify;
        font-size: 9px;
    }
    .box-trilha-box {
        margin-top: -12%;
        max-width: 88%;
        min-width: 88%;
        margin-left: 6%;
        border-radius: 8px;
        padding: 15px 0 0 15px;
        height: 45%;
    }
    .div-trilha-item span {
        font-size: 11px;
    }
}







/* tablet */
@media screen and (min-width: 768px) and (max-width: 1150px){
    .article-trilha {
        padding: 20% 0 10% 0;
    }
    .box-trilha {
        border-radius: 8px;
        height: 40vh;
        width: 90%;
        flex-wrap: wrap;
    }
    .box-trilha img {
        width: 30%;
        height: 44%;
        margin: 30px 30px;
        border-radius: 8px;
    }
    .box-trilha-conteudo {
        margin-top: 30px;
        max-width: 58%;
        min-width: 58%;
    }
    .box-trilha-conteudo h1 {
        font-size: 22px;
        font-weight: 700;
    }
    .box-trilha-conteudo p{
        max-width: 80%;
        text-align: justify;
        font-size: 14px;
    }
    .box-trilha-box {
        margin-top: -6%;
        max-width: 50%;
        min-width: 50%;
        margin-left: 4.2%;
        border-radius: 8px;
        padding: 15px 0 0 25px;
        height: 36%;
    }
    .div-trilha-item span {
        font-size: 12px;
    }
}
.body-aula{
    background-color: var(--secondary-color);
    height: 155vh;
    display: flex;
    flex-direction: column;
    color: var(--primary-text-color);
    padding: 7% 4% 2% 4%;
}

.ativo {
    color: var(--primary-color);
}  
.breadcrumb .active{
    color: var(--primary-color) !important;
}

.body-aula nav{
    --bs-breadcrumb-divider: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8'%3E%3Cpath d='M2.5 0L1 1.5 3.5 4 1 6.5 2.5 8l4-4-4-4z' fill='%236c757d'/%3E%3C/svg%3E");
}
.body-aula nav ol li a{
    color: var(--primary-text-color);
    text-decoration: none;
    font-weight: 500;
}
.body-aula nav ol li{
    font-weight: 500;
}


.box-aulas{
    height: 70vh;
    display: flex;
    grid-gap: 2%;
    gap: 2%;
    flex-wrap: wrap;
}
.box-aulas-video{
    max-width: 70%;
    min-width: 70%;
    height: 70vh;
}
.box-aulas-video iframe{
    border-radius: 10px;
    width: 100%;
    height: 100%;
}


.box-aulas-videos{
    margin-left: 2%;
    width: 25%;
    height: 62vh;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    background-color: var(--secondary-color) !important;
    box-shadow: 1px 2px 5px var(--tertiary-color);
}
.videos-nav{
    display: flex; 
    justify-content: flex-start;
    background-color: var(--header-secundary-color);
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    padding: 15px 5%;
    grid-gap: 9%;
    gap: 9%;
    border-bottom: 1px solid var(--tertiary-color);
}
.videos-nav p{
    margin: 0;
    cursor: pointer;
    font-weight: 600;
}
.videos-nav p svg{
    margin-left: 0.2vw;
    transition: 0.3s ease-in;
    margin-top: -2px;
}
.videos-nav p svg:hover{
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
}
.box-aulas-videos .botao-aula-concluida{
    background-color: var(--primary-color-3);
    border: none;
    width: 100%;
    border-radius: 5px;
    margin-top: 3.5vh;
    font-size: 14px;
    padding: 2% 0;
    box-shadow: 1px 1px 7px var(--tertiary-color);
    transition: 0.4s ease-in-out;
    font-weight: 600;
}
.box-aulas-videos .botao-aula-concluida:hover{
    background-color: var(--primary-color);
}


.box-descricao{
    display: flex;
    flex-direction: column;
    margin-top: 60px;
}
.box-descricao h1{
    font-weight: 600;
    margin-bottom: 30px;
}
.box-descricao span{
    font-weight: 600;
    padding-bottom: 10px;
    border-bottom: 1px solid var(--tertiary-color);
    width: 60%;
    font-size: 1.6rem;
}
.box-descricao p{
    margin-top: 30px;
    max-width: 90%;
}

.fileAula{
    margin-top: 2% !important;
}
.fileAula:hover{
    text-decoration: underline;
}

.modal-certificado .modal-content{
    background-color: transparent;
}
.modal-certificado .modal-body img{
    width: 90px;
}
.modal-certificado .modal-body p{
    font-size: 17px;
    width: 100%;
}
.modal-certificado .modal-body button{
    margin-top: 2vh;
}


/* celular */
@media screen and (max-width: 767px){
    .body-aula{
        height: 100%;
        padding: 28% 5% 10% 5%;
    }
    .box-aulas{
        height: 100%;
        flex-direction: column;
    }
    .box-aulas-video{
        max-width: 100%;
        min-width: 100%;
        height: 23.3vh;
        margin-bottom: 7%;
    }
    .box-aulas-videos{
        margin-left: 0%;
        width: 100%;
        height: 50vh;
    }
    .box-aulas-videos .botao-aula-concluida{
        border-radius: 5px;
        margin-top: 9%;
    }
    .box-descricao{
        margin-top: 30%;
    }
    .box-descricao h1{
        font-size: 1.5rem;
    }
    .box-descricao span{
        font-size: 1.5rem;
    }
    .box-descricao p{
        margin-top: 20px;
        max-width: 90%;
    }
}




/* tablet */
@media screen and (min-width: 768px) and (max-width: 1080px){
    .body-aula{
        height: 100%;
        padding: 17% 5% 10% 5%;
    }
    .box-aulas{
        height: 100%;
        flex-direction: column;
    }
    .box-aulas-video{
        max-width: 100%;
        min-width: 100%;
        height: 38vh;
        margin-bottom: 7%;
    }
    .box-aulas-videos{
        margin-left: 0%;
        width: 100%;
        height: 50vh;
    }
    .box-aulas-videos .botao-aula-concluida{
        border-radius: 5px;
        margin-top: 5%;
    }
    .box-descricao{
        margin-top: 30%;
    }
    .box-descricao h1{
        font-size: 1.5rem;
    }
    .box-descricao span{
        font-size: 1.5rem;
    }
    .box-descricao p{
        margin-top: 20px;
        max-width: 90%;
    }
}
.accordion{
    --bs-accordion-color: none !important;
    --bs-accordion-border-color: none !important;
    --bs-accordion-inner-border-radius: none !important;
    --bs-accordion-btn-padding-x: 1rem !important;
    --bs-accordion-active-color: none !important;
    max-height: 53vh;
    overflow-y: scroll;
}
.accordion::-webkit-scrollbar{
    background-color: var(--secondary-color) !important;
}
.accordion-item{
    max-width: 100%;
    min-width: 99%;
}
.accordion-button{
    color: var(--primary-text-color) !important;
    border-bottom: 1px solid var(--primary-text-color) !important;
    background-color: var(--secondary-color) !important;
    font-weight: 500;
}
.accordion-button:not(.collapsed)::after{
    background-image: var(--bs-accordion-btn-icon) !important;
}
.accordion-button:focus {
    box-shadow: none !important;
}
.accordion-body{
    background-color: var(--secondary-color) !important;
    display: flex;
    align-items: center;
    cursor: pointer;
    transition: 0.3s ease-in-out;
}
.accordion-body-trilha{
    padding: 10px 10px 10px 5px !important;
}
.accordion-body:hover{
    background-color: var(--header-secundary-color) !important;
}
.accordion-body p{
    margin: 0 !important;
    margin-right: 0 !important;
    font-weight: 400;
    width: 80%;
}
.accordion-body span{
    font-size: 11px;
    font-weight: 400;
}
.accordion-body svg{
    font-size: 35px;
    width: 20%;
    display: flex;
    justify-content: start;
    align-items: start;
}


/* celular */
@media screen and (max-width: 767px){
    .accordion{
        max-height: 43vh;
    }
}
.body-comentarios{
    height: 86%;
    overflow-y: auto;
}

.body-comentarios .publicar{
    display: flex;
    flex-direction: column;
    width: 100%;
    border-bottom: 1px solid var(--tertiary-color);
}
.body-comentarios .publicar textarea{
    margin: 5% 5%;
    border: 1px solid var(--tertiary-color);
    background-color: transparent;
    font-size: 14px;
    color: var(--primary-text-color);
    height: 90px;
    border-radius: 5px;
    padding: 5px;
}  
.body-comentarios button{
    background-color: var(--primary-color);
    color: var(--primary-text-color);
    border: none;
    padding: 4px 0;
    border-radius: 5px;
    font-size: 13px;
    margin-bottom: 5%;
    margin-left: 65%;
    width: 30%;
    transition: 0.3s ease-in;
    font-weight: 600;
}
.body-comentarios button:hover{
    background-color: var(--primary-color-2);
}

.box-comentario{
    border-bottom: 1px solid var(--tertiary-color);
    padding: 10px 16px;
}

.box-comentario h6{
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 16px;
    font-weight: 700;
}

.box-comentario span{
    font-size: 11px;
    margin-top: 2px;
    font-weight: 500;
}

.box-comentario p{
    font-weight: 400;
    font-size: 14px;
}
.sumir-renda{
    font-size: 13px;
    margin-bottom: 1.5vh !important;
}

.body-dashboard{
    background-color: var(--secondary-color);
    height: 100%;
    color: var(--primary-text-color);
    padding: 5% 0 1% 0;
}

.body-dashboard .capa{
    width: 100%;
    height: 230px;
}
.conteudo-dashboard{
    display: flex;
    width: 100%;
    padding: 0 5%;
    display: flex;
    justify-content: space-between;
}
.blocos-dashboard{
    background-color: var(--header-secundary-color);
    border-radius: 10px;
    box-shadow: 1px 1px 7px var(--tertiary-color);
}

.left-dashboard{
    position: relative;
    top: -7vh;
    width: 22%;
    display: flex;
    flex-direction: column;
    align-items: center;
    grid-gap: 20px;
    gap: 20px;
}




.dashboard-perfil{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}
.dashboard-perfil img, .imgperfil{
    width: 110px;
    min-width: 110px;
    max-width: 110px;
    height: 110px;
    min-height: 110px;
    max-height: 110px;
    border-radius: 50%;
    border: 3px solid var(--tertiary-color);
    margin: 30px 0 20px 0;
    object-fit: cover;
}
.dashboard-perfil h1{
    font-size: 24px;
    font-weight: 600;
    margin: 0 0 5px 0;
    width: 90%;
    text-align: center;
}
.dashboard-perfil h6{
    font-size: 14px;
    margin: 0 0 20px 0;
    text-align: center;
    width: 80%;
}
.dashboard-perfil span{
    font-size: 12px;
    font-weight: 300;
    display: flex;
    align-items: center;
}
.dashboard-perfil span svg{
    font-size: 15px;
    margin-right: 3px;
    margin-top: -5px;
}
.dashboard-perfil button{
    margin: 15px 0 0 0;
    border-radius: 7px;
    padding: 0.8vh 5%;
}
.dashboard-perfil p{
    font-size: 12px;
    margin-top: 15px;
    margin-bottom: 20px;
}
.divisao-dashboard{
    margin: 20px 0 0 0;
    border-bottom: 2px solid var(--tertiary-color);
    width: 100%;
}


.btn-certificado{
    width: 100%;
}
.btn-certificado svg{
    font-size: 24px;
    margin-right: 1.5%;
}
.btn-certificado button{
    width: 100%;
    background-color: var(--primary-color-3);
    transition: 0.4s ease-in-out;
}
.btn-certificado button:hover{
    background-color: var(--primary-color);
}


.bloco-plano{
    margin-bottom: 2.5vh;
    padding: 4% 0 3% 0;
}
.bloco-plano h2{
    font-size: 22px;
    margin-left: 0%;
    margin-bottom: 3%;
    padding-left: 8%;
    padding-bottom: 3%;
    border-bottom: 1px solid;
    border-color: var(--tertiary-color);
}
.bloco-plano div{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 4% 0;
}
.bloco-plano div p{
    margin-bottom: 0;
    padding: 0% 0 0% 8%;
}
.bloco-plano div h1{
    padding-right: 8%;
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 0;
}
.bloco-plano button{
    background-color: transparent;
    border: 1px solid var(--tertiary-color);
    padding: 0.6vh 0.2vw;
    font-weight: 700;
    border-radius: 5px;
    font-size: 13px;
    width: 40%;
    margin: 4% 0 6% 29%;
    transition: 0.3s ease-in-out;
}


.dashboard-bloco-pequeno{
    width: 100%;
}
.dashboard-bloco-pequeno p{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 5% 0 5% 8%;
}
.dashboard-bloco-pequeno p h1{
    padding-right: 8%;
    margin-bottom: 0;
    font-size: 25px;
    font-weight: 600;
}




.dashboard-rede{
    width: 25%;
    padding: 20px 0 0 0;
    margin: 20px 0 0 0;
    height: 75vh;
}
.rede-overflow{
    overflow-y: scroll;
    overflow-x: hidden;
    height: 65vh;
}
.conteudo-dashboard  .dashboard-rede .rede-overflow::-webkit-scrollbar{
    width: 6px;
    background-color: var(--secondary-color);
    border: none;
}
.dashboard-rede h1{
    margin-left: 5%;
    margin-bottom: 6%;
    font-size: 22px;
}
.rede{
    display: flex;
    align-items: center;
    grid-gap: 10px;
    gap: 10px;
    border-top: 1px solid;
    border-color: var(--tertiary-color);
    padding: 4% 0;
}
.rede h6{
    margin-left: 10px;
    width: 8%;
    font-size: 16px;
    font-weight: 600;
    padding-top: 5px;
}
.rede img{
    object-fit: cover;
    width: 44px;
    min-width: 44px;
    max-width: 44px;
    height: 44px;
    min-height: 44px;
    max-height: 44px;
    border-radius: 50%;
    border: 2px solid var(--tertiary-color);
}
.rede div{
    width: 70%;
}
.rede p{
    font-size: 14px;
    font-weight: 600;
    width: 95%;
    margin-bottom: 0;
}
.rede span{
    font-size: 10px;
    width: 100%;
    font-weight: 600;
    flex-wrap: wrap;
    color: var(--primary-color-3);
}
.rede-line{
    line-height: 2vh;
}



.dashboard-sobre-mim{
    margin: 20px 0;
    width: 43%;
    height: 65vh;
}
.dashboard-sobre-mim svg{
    position: absolute;
    right: 6%;
    margin-top: 1%;
    font-size: 22px;
    cursor: pointer;
}
.dashboard-sobre-mim h1{
    font-size: 22px;
    margin-left: 3%;
    padding-top: 20px;
}

.dashboard-sobre-mim p{
    margin-left: 4%;
    padding-right: 10%;
    height: 100%;
    padding-bottom: 40px;
}
.bloco-sobre-mim{
    height: 83%;
}
.bloco-sobre-mim h1{
    margin-left: 0%;
    margin-bottom: 3%;
    padding-left: 3%;
    padding-bottom: 3%;
    border-bottom: 1px solid;
    border-color: var(--tertiary-color);
}
.bloquinhos{
    display: flex;
    margin-left: 0;
    justify-content: space-between;
    margin-top: 3%;
}
.bloquinhos p{
    background-color: var(--secondary-color);
    border-radius: 10px;
    display: flex;
    width: 48%;
    margin-left: 0;
    align-items: center;
    justify-content: space-between;
    padding: 2% 0 2% 3.5%;
    box-shadow: 1px 1px 7px var(--tertiary-color);
}
.bloquinhos p h1{
    padding-right: 8%;
    font-size: 27px;
    padding-top: 0;
    margin-bottom: 0;
}



.modal-dashboard .presencial-botao{
    width: 70%;
    margin-top: 2px;
}
.btn-close{
    --bs-btn-close-focus-shadow: transparent;
}
.modal-dashboard .modal-content{
    border-radius: 30px;
}
.modal-dashboard .modal-header{
    background-color: var(--secondary-color);
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    width: 40vw;
    color: var(--primary-text-color);
}
.modal-dashboard .modal-header button:first-child{
    background-color: var(--primary-color-3);
    color: var(--primary-text-color);
}
.modal-dashboard .modal-header button:first-child:hover{
    background-color: var(--primary-color);
}
.modal-dashboard .modal-body{
    background-color: var(--secondary-color);
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    height: 75vh;
    width: 40vw;
    display: flex;
    flex-direction: column;
    color: var(--primary-text-color);
    padding: 0 0 10px 0;
}
.modal-dashboard .modal-content{
    border: none;
    height: 75vh;
    width: 40vw;
}
.modal-body-capa{
    width: 100%;
}
.conteudo-modal-body{
    display: flex;
    flex-direction: column;
    padding-left: 4%;
    padding-right: 4%;
}
.conteudo-modal-body img{
    width: 90px;
    min-width: 90px;
    max-width: 90px;
    height: 90px;
    min-height: 90px;
    max-height: 90px;
    margin-top: -40px;
    border-radius: 50%;
    border: 3px solid var(--tertiary-color);
    margin-bottom: 7px;
}
.label-avatar{
    cursor: pointer;
    display: flex;
    flex-direction: column;
}
.label-avatar svg{
    z-index: 99;
    position: absolute;
    margin-left: 5.7%;
    margin-top: -2%;
    opacity: 0.9;
    transition: all 0.5s;
}
.label-avatar svg:hover{
    opacity: 1;
    -webkit-transform: scale(1.3);
            transform: scale(1.3);
}
.botao-upload{
    background-color: var(--green-color) !important;
    width: 23%;
    border: none;
    padding: 0.7% 1%;
    border-radius: 5px;
    font-size: 12px;
    box-shadow: 1px 1px 5px var(--tertiary-color);
    transition: 0.4s ease-in-out;
    margin-bottom: 5%;
}
.botao-upload:hover{
    background-color: var(--2-green-color);
}
.input-modal{
    display: flex;
    flex-direction: column;
    width: 100%;
    border: 1px solid var(--tertiary-color);
    margin-bottom: 4%;
    border-radius: 4px;
    padding: 5px 9px;
}
.input-modal label{
    font-size: 12px;
    color: var(--primary-color-4);
    margin-bottom: 2px;
}
.input-modal input, textarea{
    background-color: transparent;
    border: none;
    outline: none;
    color: var(--primary-text-color);
}
.select-dashboard{
    margin-bottom: 0;
    background-color: transparent;
    border: none;
    width: 30%;
    outline: none;
    color: var(--primary-text-color);
}
.select-dashboard option{
    color: var(--primary-text-color);
    background-color: var(--secondary-color);
}



.modal-dashboard2 .modal-body{
    height: 50vh;
}
.modal-body2{
    padding: 4% !important;
}
.modal-body2 div textarea{
    height: 39vh;
    max-height: 39vh;
}
.botao-modal-dashboard2{
    width: 25%;
}



.modal-cancelar-plano .modal-header {
    border-bottom: none;
    width: 30vw;
}
.modal-cancelar-plano .modal-header button{
    background-color: transparent !important;
}
.modal-cancelar-plano .modal-body{
    width: 30vw;
}
.modal-cancelar-plano .modal-content{
    height: 70vh;
    width: 30vw;
    background-color: transparent;
    margin-left: 7%;
}

.modal-body-cancelar1, .modal-body-cancelar2{
    padding: 6% 5% 5% 5% !important;
    display: flex;
    align-items: center;
    text-align: center;
}
.modal-body-cancelar1 svg{
    font-size: 75px;
    margin-bottom: 6%;
}
.modal-body-cancelar1 h6{
    font-size: 21px;
    font-weight: 600;
    width: 80%;
    margin-bottom: 4%;
}
.modal-body-cancelar1 p{
    width: 80%;
    font-weight: 400;
    margin-bottom: 7%;
}
.modal-body-cancelar1 .btn-prosseguir{
    background-color: var(--primary-color-3);
    border: none;
    padding: 0.6vh 3.7vw;
    border-radius: 5px;
    font-size: 16px;
    box-shadow: 1px 1px 6px var(--tertiary-color);
    transition: 0.3s ease-in-out;
    margin-bottom: 2%;
}
.modal-body-cancelar2 .btn-cancelar{
    background-color: var(--dark-red-color);
    border: none;
    padding: 0.6vh 1.5vw;
    border-radius: 5px;
    font-size: 16px;
    font-weight: 600;
    box-shadow: 1px 1px 8px var(--tertiary-color);
    transition: 0.3s ease-in-out;
    margin-bottom: 2%;
}
.modal-body-cancelar2 .btn-cancelar:hover{
    background-color: var(--2-dark-red-color);
}
.modal-cancelar-plano .btn-voltar{
    background-color: transparent;
    border: 1px solid var(--tertiary-color);
    padding: 0.6vh 4.9vw;
    border-radius: 5px;
    font-size: 16px;
    transition: 0.3s ease-in-out;
}
.modal-body-cancelar1 .btn-prosseguir:hover{
    background-color: var(--primary-color);
}
.modal-body-cancelar2{
    padding: 2% 5% 5% 5% !important;
}
.modal-body-cancelar2 p{
    font-weight: 600;
    width: 80%;
}
.modal-body-cancelar2 div{
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: start;
}
.modal-body-cancelar2 div label input{
    margin-right: 0.7vw;
}



/* celular e tablet */
@media screen and (max-width: 1080px){
    .body-dashboard{
        padding: 10vh 0 5% 0;
    }
    .body-dashboard .capa{
        width: 100%;
        height: 100%;
    }
    .conteudo-dashboard{
        flex-direction: column;
        padding: 0 10%;
        align-items: center;
    }
    .left-dashboard{
        top: 3vh;
        width: 90%;
    }
    .dashboard-perfil button{
        margin: 15px 0 0 2vw;
        text-align: center;
    }
    .dashboard-rede{
        width: 90%;
        margin: 3.5vh 0 0 0;
    }
    .dashboard-rede h1{
        font-size: 18px;
    }
    .dashboard-sobre-mim{
        margin: 25px 0;
        width: 90%;
        height: 50vh;
    }
    .dashboard-sobre-mim svg{
        right: 17%;
        margin-top: 3%;
    }
    .dashboard-sobre-mim h1{
        font-size: 18px;
    }

    
    .modal-dashboard .modal-header{
        width: 80vw;
        margin-left: 5vw;
    }
    .modal-dashboard .modal-header h5{
        width: 50%;
    }
    .modal-dashboard .modal-body{
        height: 65vh;
        width: 80vw;
        margin-left: 5vw;
    }
    .modal-dashboard .modal-content {
        height: 65vh;
        width: 80vw;
        margin-left: 3vw;
        background-color: transparent !important;
    }
    .modal-dashboard .presencial-botao {
        width: 50%;
    }
    .botao-modal-dashboard2{
        width: 80px;
        margin-right: 1vw;
    }
    .label-avatar svg {
        margin-left: 10%;
        margin-top: -2.5%;
    }
    .botao-upload {
        width: 34%;
    }
    .modal-dashboard .modal{
        --bs-modal-width: 100vw;
    }
    .modal-cancelar-plano .modal-header {
        width: 80vw;
    }
    .modal-cancelar-plano .modal-body{
        width: 80vw;
    }
    .modal-cancelar-plano .modal-content{
        height: 55vh;
        width: 80vw;
        margin-left: 2.5%;
    }






    .modal-body-cancelar1, .modal-body-cancelar2{
        padding: 6% 5% 5% 5% !important;
    }
    .modal-body-cancelar1 svg{
        font-size: 70px;
    }
    .modal-body-cancelar1 h6{
        width: 90%;
    }
    .modal-body-cancelar1 p{
        width: 95%;
    }
    .modal-body-cancelar1 .btn-prosseguir{
        padding: 0.6vh 7vw;
        margin-bottom: 3%;
    }
    .modal-body-cancelar2 .btn-cancelar{
        padding: 0.6vh 4vw;
        margin-bottom: 3%;
    }
    .modal-body-cancelar2 .btn-voltar{
        padding: 0.6vh 16vw;
    }
    .modal-body-cancelar1 .btn-voltar{
        padding: 0.6vh 11vw;
    }
    .modal-body-cancelar2{
        padding: 4% 5% 5% 5% !important;
    }
    .modal-body-cancelar2 p{
        width: 95%;
    }
    .modal-body-cancelar2 div label input{
        margin-right: 1.5vw;
    }




    .bloquinhos{
        flex-direction: column;
        margin-top: 6%;
    }
    .bloquinhos p{
        width: 100%;
        padding: 5% 0 5% 4.5%;
    }
    .bloquinhos p h1{
        padding-right: 8%;
        font-size: 27px;
        padding-top: 0;
        margin-bottom: 0;
    }
}
.body-adote-um-aluno{
    color: var(--primary-text-color);
    display: flex;
    flex-direction: column;
}

.body-adote-um-aluno h1{
    text-align: center;
    padding: 8% 10% 0 10%;
    font-weight: 700;
}
.body-adote-um-aluno .span-borda{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 0.8% 0 3% 0;
}
.body-adote-um-aluno .span-borda span{
    border-bottom: 1px solid var(--tertiary-color);
    width: 20%;
}
.body-adote-um-aluno h2{
    text-align: center;
    padding: 0 10%;
    font-weight: 600;
}
.body-adote-um-aluno p{
    padding: 0 10%;
    text-align: justify;
}
.body-adote-um-aluno a{
    display: flex;
    align-items: center;
    justify-content: center;
}

.body-adote-um-aluno .video-adotar{
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 0 6% 0;
}
.body-adote-um-aluno iframe{
    width: 68vw;
    height: 75vh;
    margin: 7vh 0 0 0;
    border-radius: 10px;
}


.container-adotar{
    display: flex;
    background-color: var(--tertiary-color);
    color: var(--secondary-text-color);
    height: 100vh;
    align-items: center;
    margin-bottom: 6%;
    width: 100%;
    border-top: 1px solid;
    border-bottom: 1px solid;
    border-color: var(--tertiary-color);
}
.adotar-left{
    width: 45%;
    padding-left: 4%;
    display: flex;
    justify-content: center;
}
.adotar-left div{
    width: 78%;
    background-color: var(--primary-color);
    padding: 10% 8%;
    border-radius: 10px;
}
.adotar-left div p svg{
    font-size: 20px;
    margin-right: 2%;
}
.adotar-left div p{
    padding: 0;
}
.adotar-right{
    width: 55%;
    display: flex;
    flex-direction: column;
    align-items: end;
    padding-right: 7%;
}
.adotar-right p{
    text-align: end;
    padding: 0;
}
.adotar-right p:first-child{
    font-size: 22px;
    font-weight: 800;
}
.adotar-right p:nth-child(2){
   width: 80%;
}
.adotar-right a{
    width: 100%;
}
.adotar-right button{
    background-color: var(--primary-color);
    border: none;
    padding: 1.5vh 0;
    border-radius: 8px;
    box-shadow: 1px 1px 8px var(--tertiary-color);
    transition: 0.4s ease-in-out;
    font-weight: 600;
    width: 23%;
    margin-right: -77%;
}
.adotar-right button:hover{
    background-color: var(--primary-color-2);
}
.adotar-valor{
    display: flex;
    margin: 5% 0 3% 0;
    align-items: center;
    flex-direction: column;
}
.adotar-valor h6{
    font-size: 25px;
    margin: 0;
    width: 90%;
}
.adotar-valor h2{
    font-size: 60px;
    font-weight: 700;
    display: flex;
    align-items: end;
}
.adotar-valor h2 h5{
    padding-bottom: 2%;
    margin-right: 2%;
}
.container-adotar2{
    margin-bottom: 0;
}

.carossel-adotar{
    display: flex;
    justify-content: center;
    width: 100%;
    align-items: center;
    padding-bottom: 7%;
    border-bottom: 1px solid;
    border-color: var(--tertiary-color);
}
.familias-impactadas-h1{
    font-size: 45px;
}
.familias-impactadas{
    font-size: 25px;
    font-weight: 600;
}
.carossel-adotar .carossel-historia{
    width: 50%;
}


.cases-adotar{
    width: 100%;
    display: flex;
    justify-content: center;
    padding-bottom: 5%;
}
.cases-adotar p{
    padding: 0;
}
.cases-adotar-h2{
    margin-top: 7%;
    margin-bottom: 4%;
}
.body-adote-um-aluno .cases-adotar .cases-card{
    color: var(--primary-text-color);
}
.body-adote-um-aluno .cases-adotar .cases-card h6 p{
    color: var(--primary-color);
}
.body-adote-um-aluno .cases-adotar .cases-card h6 a{
    text-decoration: underline;
}




/* celular e tablet */
@media screen and (max-width: 1080px){
    .first-h1{
        margin-top: 14vh;
    }
    .body-adote-um-aluno h1{
        padding: 0 5%;
    }
    .body-adote-um-aluno .span-borda span{
        width: 35%;
    }
    .body-adote-um-aluno p{
        padding: 0 5%;
    }
    .body-adote-um-aluno iframe{
        margin: 3vh 0 1vh 0;
        border-radius: 8px;
        width: 90vw;
        height: 23vh;
    }
    .container-adotar{
        flex-direction: column;
        height: 100%;
    }
    .adotar-left{
        width: 100%;
        padding-left: 0%;
    }
    .adotar-left div{
        width: 80%;
        padding: 6% 7%;
        margin: 8% 0;
        border-radius: 8px;
    }
    .adotar-left div p svg{
        margin-right: 4%;
    }
    .adotar-right{
        width: 85%;
        align-items: start;
        padding-right: 0%;
        padding-bottom: 7%;
    }
    .adotar-right p{
        text-align: start;
    }
    .adotar-right p:nth-child(2){
        width: 100%;
     }
    .adotar-right a{
        width: 100%;
    }
    .adotar-right button{
        padding: 1vh 0;
        width: 50%;
        margin-right: 0%;
    }
    .adotar-valor{
        width: 85%;
    }
    .carossel-adotar{
        flex-direction: column;
    }
    .familias-impactadas-h1{
        font-size: 40px;
    }
    .familias-impactadas{
        font-size: 20px;
    }
    .carossel-adotar .carossel-historia{
        width: 90%;
    }
    .adotar-case-h2{
        padding: 0 !important;
    }
}
.disabled {
    text-align: left;
}

.disabled span {
    margin-left: 1vw;
    font-size: 12px;
    display: flex;
    align-items: center;
    white-space: nowrap;
}

.disabled span svg {
    margin-right: 0.3vw;
    font-size: 18px;
}

.body-pagamento-doar {
    padding: 0 0 0 !important;
    height: 100%;
}
.body-pagamento-doar .accordion{
    padding-bottom: 8%;
}
.body-pagamento-doar .cadeado{
    margin-top: 10%;
}

.body-pagamento-doar .accordion .accordion-item{
    margin-bottom: 1%;
}

.body-pagamento-doar .accordion .accordion-item #collapseThree .accordion-body{
    padding: 5% 4% 6% 4% !important;
    box-shadow: 1px 2px 5px var(--tertiary-color);
}

.body-pagamento-doar .accordion .accordion-item #collapseThree .accordion-body
.credito-formulario button{
    margin-top: 2%;
}

.body-pagamento-doar .accordion-item:last-of-type .accordion-button.collapsed {
    border-bottom-right-radius: 6px !important;
    border-bottom-left-radius: 6px !important; 
}


.body-pagamento-doar .cartoes-credito {
    display: flex;
    grid-gap: 0.6vw;
    gap: 0.6vw;
    justify-content: start;
    margin-left: 2%;
}

.body-pagamento-doar .cartoes-credito img {
    width: 5vh !important;
}

.credito-formulario .formulario-pagamento button {
    background-color: var(--primary-color-3);
    color: var(--primary-text-color);
    border: none;
    padding: 1vh 7px;
    width: 100%;
    border-radius: 7px;
    cursor: pointer;
    font-size: 16px;
    font-weight: 600;
    box-shadow: 1px 1px 6px var(--tertiary-color);
    transition: 0.3s ease-in-out;
}

.componente-credito .credito-formulario .formulario-pagamento button:hover {
    background-color: var(--primary-color);
}

.componente-credito .credito-formulario .formulario-pagamento .summary {
    margin-top: 5%;
}

.credito-formulario .formulario-pagamento {
    margin: 4% 0 0 0;
}

.componente-credito .credito-formulario .formulario-pagamento div {
    display: flex;
    flex-direction: column;
}

.componente-credito .credito-formulario .formulario-pagamento div input,
div select {
    margin-bottom: 1.7vh;
    background-color: transparent;
    border: 1px solid var(--tertiary-color);
    color: var(--primary-text-color);
    outline: none;
    padding: 1.5vh 1vw;
    border-radius: 5px;
}

.formulario-pagamento div select option {
    color: var(--primary-text-color);
}

.formulario-pagamento div select option:hover {
    color: var(--tertiary-color);
}

#accordionExample {
    overflow-y: visible !important;
}

.credito-formulario {
    margin: 2% 0 0.5% 0;
    width: 100%;

}

.credito-formulario span {
    font-size: 13px;
}

.credito-formulario .formulario-pagamento {
    margin: 4% 0 0 0;
}

.pagamento-doar-valor{
    display: flex !important;
    flex-direction: row !important;
    align-items: center !important;
    border: 1px solid var(--tertiary-color);
    border-radius: 5px;
    padding: 0 1vw;
    grid-gap: 0.5vw;
    gap: 0.5vw;
}
.pagamento-doar-valor input{
    border: none !important;
    padding: 0 !important;
    margin-top: 1.5vh !important;
}


.credito-formulario .formulario-pagamento div {
    display: flex;
    flex-direction: column;
}

.credito-formulario .formulario-pagamento div input,
div select {
    margin-bottom: 1.7vh;
    background-color: transparent;
    border: 1px solid var(--tertiary-color);
    color: var(--primary-text-color);
    outline: none;
    padding: 1.5vh 1vw;
    border-radius: 5px;
}

.body-pagamento-doar {
    color: var(--primary-text-color);
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 5% 0;
}

.body-pagamento-doar .cadeado {
    font-size: 60px;
    border: 2px solid var(--tertiary-color);
    padding: 8px;
    border-radius: 50%;
    margin-bottom: 1%;
}

.body-pagamento-doar h3 {
    font-weight: 700;
}
.body-pagamento-doar .credito-formulario h3 {
    margin-bottom: 3%;
}

.body-pagamento-doar .p1 {
    width: 30%;
    text-align: center;
}


.body-pagamento-doar .accordion {
    width: 45%;
    margin-bottom: 1%;
    overflow-y: hidden !important;
}

.body-pagamento-doar .accordion-header .accordion-button {
    background-color: var(--secondary-color) !important;
    border: 2px solid var(--tertiary-color) !important;
    border-radius: 6px;
    padding-left: 2vw;
    transition: 0.2s ease-in;
}

.accordion-button p {
    padding-left: 0 !important;
}


.body-pagamento-doar .accordion-header .accordion-button:hover {
    background-color: var(--header-secundary-color) !important;
}

.body-pagamento-doar .accordion-header {
    background-color: var(--secondary-color) !important;
}

.body-pagamento-doar .accordion-header div {
    display: flex;
    align-items: center;
}

.body-pagamento-doar .accordion-header div p {
    margin-bottom: 0;
    padding: 5% 0 5% 10%;
}

.body-pagamento-doar .accordion-header div .pix {
    width: 22%;
    margin-left: 8%;
}

.body-pagamento-doar .accordion-body {
    display: flex;
    align-items: center;
    width: 100%;
    padding: 6% 4% !important;
    cursor: auto;
    box-shadow: 1px 2px 5px var(--tertiary-color);
}

.body-pagamento-doar .accordion-body img {
    width: 210px;
    /* height: auto; */
    margin-right: 5%;
    border-radius: 8px;
}

.body-pagamento-doar .accordion-body div p {
    width: 100%;
    margin-bottom: 2vh !important;
    font-size: 15.3px;
}

.body-pagamento-doar .accordion-body div p span {
    font-size: 15.3px;
    font-weight: 600;
}

.body-pagamento-doar .accordion-body div p a {
    text-decoration: underline;
    color: var(--primary-color);
    font-weight: 600;
}

.body-pagamento-doar .accordion-button:not(.collapsed)::after {
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAYAAAAeP4ixAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAxElEQVR4nO3XTQrCMBRF4eBAdLfOXJiD2s15REQo2lBb83Of3AMZZZKPRwYvJeecc845Fzbg8DgpcsAeuAAjcEzBEa/iYfhExMPw/BMD+Qb5P8P8JM7ACbiFmAwZxOReH8MCIgSGLxHSGFYiJDFsREhh+BEhgaEQoiuGwoguGCohmmKojGiCoRGiKobGiCqYXoiimN6IIhgVxE8YNcQmjCpiNWYGIoPIYMbsyjzByCHeMNfFvR/YJfEI8EbnnHPOufRH3QEEO8YvyMQIUAAAAABJRU5ErkJggg==) !important;
    margin-right: 1vw !important;
}

.accordion-button::after {
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAYAAAAeP4ixAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAxElEQVR4nO3XTQrCMBRF4eBAdLfOXJiD2s15REQo2lBb83Of3AMZZZKPRwYvJeecc845Fzbg8DgpcsAeuAAjcEzBEa/iYfhExMPw/BMD+Qb5P8P8JM7ACbiFmAwZxOReH8MCIgSGLxHSGFYiJDFsREhh+BEhgaEQoiuGwoguGCohmmKojGiCoRGiKobGiCqYXoiimN6IIhgVxE8YNcQmjCpiNWYGIoPIYMbsyjzByCHeMNfFvR/YJfEI8EbnnHPOufRH3QEEO8YvyMQIUAAAAABJRU5ErkJggg==) !important;
    margin-right: 1vw !important;
}


.body-pagamento-doar .container-opcao {
    width: 45%;
    border: 2px solid var(--tertiary-color);
    margin-bottom: 1%;
    border-radius: 6px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1.5% 2%;
    transition: 0.2s ease-in;
}

.body-pagamento-doar .container-opcao:hover {
    background-color: var(--header-secundary-color);
}

.body-pagamento-doar .container-opcao svg {
    font-size: 22px;
}

.body-pagamento-doar .container-opcao div {
    display: flex;
    align-items: center;
}

.body-pagamento-doar .container-opcao div .pix {
    width: 22%;
    margin-left: 8%;
}

.body-pagamento-doar .container-opcao div .boleto {
    width: 20%;
    margin-left: 6%;
}

.body-pagamento-doar .container-opcao div .credito {
    margin-left: 3%;
    display: flex;
    grid-gap: 4%;
    gap: 4%;
}

.body-pagamento-doar .container-opcao div p {
    margin-bottom: 0;
    white-space: nowrap;
}

.body-pagamento-doar .container-opcao div span {
    margin-left: 1vw;
    font-size: 12px;
    display: flex;
    align-items: center;
}

.body-pagamento-doar .container-opcao div span svg {
    margin-right: 0.3vw;
    font-size: 18px;
}



.modal-doar .modal-body{
    background-color: var(--secondary-color);
    border-radius: 8px;
    padding: 8% 7% 10% 7%;
    display: flex;
    align-items: center;
    flex-direction: column;
    color: var(--primary-text-color);
}

.modal-doar .modal-body img{
    width: 20%;
    margin-bottom: 7%;
}

.modal-doar .modal-body h2{
    font-size: 1.3rem;
    font-weight: 700;
    text-align: center;
}

.modal-doar .modal-body h6{
    font-size: 1.1rem;
    font-weight: 700;
}

.modal-doar .modal-body p{
    padding-top: 3%;
    font-size: 1rem;
    text-align: center;
}















/* celular e tablet */
@media screen and (max-width: 1080px) {
    .body-pagamento-doar {
        padding: 12% 0;
    }

    .body-pagamento-doar .cadeado {
        margin-bottom: 5%;
        margin-top: 15vh;
    }

    .body-pagamento-doar h3 {
        text-align: center;
        width: 85%;
    }

    .body-pagamento-doar .p1 {
        width: 80%;
    }

    .body-pagamento-doar .accordion {
        width: 85%;
        margin-bottom: 4%;
    }

    .body-pagamento-doar .accordion-header div p {
        padding: 5% 0 5% 6px !important;
    }

    .body-pagamento-doar .accordion-body {
        padding: 6% 4% !important;
        flex-direction: column;
    }

    .body-pagamento-doar .accordion-body img {
        width: 250px;
        margin-right: 0;
        margin-bottom: 7%;
        border-radius: 6px;
    }

    .body-pagamento-doar .accordion-body div p {
        font-size: 16px;
        text-align: center;
    }

    .body-pagamento-doar .accordion {
        max-height: 100%;
    }

    .body-pagamento-doar .container-opcao {
        width: 85%;
        margin-bottom: 4%;
        padding: 5% 5%;
    }

    .body-pagamento-doar .container-opcao div {
        flex-direction: column;
        align-items: start;
    }

    .body-pagamento-doar .container-opcao div span {
        margin-left: 0;
    }

    .body-pagamento-doar .container-opcao div span svg {
        margin-right: 1.3vw;
        background-color: var(--dark-red-color) !important;
    }
    .pagamento-doar-valor{
        margin-bottom: 2%;
    }
    .disabled span {
        margin-left: 2vw;
    }
    .body-pagamento-doar .credito-formulario h3{
        width: 100%;
        text-align: start;
        font-size: 1.4rem;
    }
    .body-pagamento-doar .accordion-header .cartoes-credito{
        margin-left: 2vw;
    }
    .body-pagamento-doar .accordion-header .cartoes-credito img{
        width: 2.5vh !important;
    }
}
.centerflex{
    align-items: center;
    justify-content: center;
    display: flex;
}
.centerflex img{
    width: 100%;
}

.body-pagamento-adote-um-aluno{
    color: var(--primary-text-color);
    display: flex;
    flex-direction: column;
    align-items: center;
}

.body-pagamento-adote-um-aluno .breadcrumb{
    margin-top: 16vh;
    width: 100%;
    font-weight: 500;
    display: flex;
    flex-direction: row;
    margin-left: -125%;
    margin-bottom: 15%;
}
.body-pagamento-adote-um-aluno nav {
    --bs-breadcrumb-divider: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8'%3E%3Cpath d='M2.5 0L1 1.5 3.5 4 1 6.5 2.5 8l4-4-4-4z' fill='%236c757d'/%3E%3C/svg%3E");
}
.cadeado{
    font-size: 60px;
    border: 2px solid var(--tertiary-color);
    padding: 8px;
    border-radius: 50%;
    margin-bottom: 1%;
}
.body-pagamento-adote-um-aluno span{
    margin: 2% 0 0.5% 0;
    font-size: 13px;
}
.body-pagamento-adote-um-aluno h3{
    font-weight: 700;
}
.body-pagamento-adote-um-aluno .credito-formulario h3 {
    margin-bottom: 3%;
}
.body-pagamento-adote-um-aluno .p1{
    width: 40%;
    text-align: center;
}
.body-pagamento-adote-um-aluno .container-opcao{
    width: 45%;
    border: 2px solid var(--tertiary-color);
    margin-bottom: 1%;
    border-radius: 6px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1.5% 2%;
    transition: 0.2s ease-in;
}
.body-pagamento-adote-um-aluno .container-opcao:hover{
    background-color: var(--header-secundary-color);
}
.body-pagamento-adote-um-aluno .container-opcao svg{
    font-size: 22px;
}
.body-pagamento-adote-um-aluno .container-opcao div{
    display: flex;
    align-items: center;
}
.body-pagamento-adote-um-aluno .container-opcao div span{
    white-space: nowrap;
    margin-left: 3%;
}
.body-pagamento-adote-um-aluno .container-opcao div .pix{
    width: 4vh;
    margin-left: 4%;
}
.body-pagamento-adote-um-aluno .container-opcao div .boleto{
    width: 5vh;
    margin-left: 3%;
}
.body-pagamento-adote-um-aluno .container-opcao div .credito{
    margin-left: 3%;
    display: flex;
    grid-gap: 4%;
    gap: 4%;
}
.body-pagamento-adote-um-aluno .container-opcao div p{
    margin-bottom: 0;
    white-space: nowrap;
}





.body-pagamento-adote-um-aluno .accordion .accordion-item #collapseThree .accordion-body{
    padding: 5% 4% 6% 4% !important;
    box-shadow: 1px 2px 5px var(--tertiary-color);
}

.body-pagamento-adote-um-aluno .accordion .accordion-item #collapseThree .accordion-body
.credito-formulario button{
    margin-top: 2%;
}

.body-pagamento-adote-um-aluno .accordion {
    width: 45%;
    margin-bottom: 1%;
    overflow-y: hidden !important;
}

.body-pagamento-adote-um-aluno .accordion-header .accordion-button {
    background-color: var(--secondary-color) !important;
    border: 2px solid var(--tertiary-color) !important;
    border-radius: 6px;
    padding-left: 2vw;
    transition: 0.2s ease-in;
}

.accordion-button p {
    padding-left: 0 !important;
}


.body-pagamento-adote-um-aluno .accordion-header .accordion-button:hover {
    background-color: var(--header-secundary-color) !important;
}

.body-pagamento-adote-um-aluno .accordion-header {
    background-color: var(--secondary-color) !important;
}

.body-pagamento-adote-um-aluno .accordion-item:last-of-type .accordion-button.collapsed {
    border-bottom-right-radius: 6px !important;
    border-bottom-left-radius: 6px !important; 
}

.body-pagamento-adote-um-aluno .accordion-header div {
    display: flex;
    align-items: center;
    padding: 0.7% 0%;
}

.body-pagamento-adote-um-aluno .accordion-header div p {
    margin-bottom: 0;
    padding: 5% 0 5% 10%;
}

.body-pagamento-adote-um-aluno .accordion-header div .pix {
    width: 22%;
    margin-left: 8%;
}

.body-pagamento-adote-um-aluno .accordion-body {
    display: flex;
    align-items: center;
    width: 100%;
    padding: 6% 4% !important;
    cursor: auto;
}

.body-pagamento-adote-um-aluno .accordion-body img {
    width: 210px;
    /* height: auto; */
    margin-right: 5%;
    border-radius: 8px;
}

.body-pagamento-adote-um-aluno .accordion-body div p {
    width: 100%;
    margin-bottom: 2vh;
    font-size: 15.3px;
}

.body-pagamento-adote-um-aluno .accordion-body div p span {
    font-size: 15.3px;
    font-weight: 600;
}

.body-pagamento-adote-um-aluno .accordion-body div p a {
    text-decoration: underline;
    color: var(--primary-color);
    font-weight: 600;
}

.body-pagamento-adote-um-aluno .accordion-button:not(.collapsed)::after {
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAYAAAAeP4ixAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAxElEQVR4nO3XTQrCMBRF4eBAdLfOXJiD2s15REQo2lBb83Of3AMZZZKPRwYvJeecc845Fzbg8DgpcsAeuAAjcEzBEa/iYfhExMPw/BMD+Qb5P8P8JM7ACbiFmAwZxOReH8MCIgSGLxHSGFYiJDFsREhh+BEhgaEQoiuGwoguGCohmmKojGiCoRGiKobGiCqYXoiimN6IIhgVxE8YNcQmjCpiNWYGIoPIYMbsyjzByCHeMNfFvR/YJfEI8EbnnHPOufRH3QEEO8YvyMQIUAAAAABJRU5ErkJggg==) !important;
    margin-right: 1vw !important;
}

.accordion-button::after {
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAYAAAAeP4ixAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAxElEQVR4nO3XTQrCMBRF4eBAdLfOXJiD2s15REQo2lBb83Of3AMZZZKPRwYvJeecc845Fzbg8DgpcsAeuAAjcEzBEa/iYfhExMPw/BMD+Qb5P8P8JM7ACbiFmAwZxOReH8MCIgSGLxHSGFYiJDFsREhh+BEhgaEQoiuGwoguGCohmmKojGiCoRGiKobGiCqYXoiimN6IIhgVxE8YNcQmjCpiNWYGIoPIYMbsyjzByCHeMNfFvR/YJfEI8EbnnHPOufRH3QEEO8YvyMQIUAAAAABJRU5ErkJggg==) !important;
    margin-right: 1vw !important;
}

.body-pagamento-adote-um-aluno .cartoes-credito {
    display: flex;
    grid-gap: 0.6vw;
    gap: 0.6vw;
    justify-content: start;
    margin-left: 2%;
}

.body-pagamento-adote-um-aluno .cartoes-credito img {
    width: 5vh !important;
}



.credito-formulario{
    margin: 0;
    width: 100%;
}
.credito-formulario span{
    font-size: 13px;
}
.credito-formulario .cartoes-credito{
    display: flex;
    grid-gap: 2%;
    gap: 2%;
}
.credito-formulario .formulario-pagamento{
    margin: 0 0 0 0;
}
.credito-formulario .formulario-pagamento div{
    display: flex;
    flex-direction: column;
}
.credito-formulario .formulario-pagamento div input, div select{
    margin-bottom: 1.7vh;
    background-color: transparent;
    border: 1px solid var(--tertiary-color);
    color: var(--primary-text-color);
    outline: none;
    padding: 1.5vh 1vw;
    border-radius: 5px;
}
.credito-formulario .formulario-pagamento div input::-webkit-input-placeholder{
    color: var(--border-gray-color);
}
.credito-formulario .formulario-pagamento div input::placeholder{
    color: var(--border-gray-color);
}
.formulario-pagamento div select option{
    color: var(--primary-text-color);
}
.formulario-pagamento div select option:hover{
    color: var(--tertiary-color);
}
.row>* {
    padding-left: 0;
}
.credito-formulario .formulario-pagamento button{
    background-color: var(--primary-color-3);
    color: var(--primary-text-color);
    border: none;
    padding: 1vh 7px;
    width: 100%;
    border-radius: 7px;
    cursor: pointer;
    font-size: 16px;
    font-weight: 600;
    box-shadow: 1px 1px 6px var(--tertiary-color);
    transition: 0.3s ease-in-out;
}
.credito-formulario .formulario-pagamento button:hover{
    background-color: var(--primary-color);
}
.summary{
    display: flex;
    flex-direction: column;
    border-radius: 30px;
    padding: 4vh 0;
}
.summary p{
    margin-bottom: 0 !important;
}
.total-diviser{
    border-bottom: 1px solid var(--tertiary-color);
    width: 60% !important;
    padding: 1% 0 !important;
    margin-bottom: 2vh !important;
}
.total-di{
    padding: 3% 0 0 0 ;
}







/* celular e tablet */
@media screen and (max-width: 1080px) {
    .body-pagamento-adote-um-aluno .breadcrumb{
        margin-top: 14vh;
        margin-left: -48%;
        margin-bottom: 20%;
    }
    .body-pagamento-adote-um-aluno .cadeado{
        margin-bottom: 5%;
    }
    .body-pagamento-adote-um-aluno h3 {
        text-align: center;
        width: 90%;
    }
    .body-pagamento-adote-um-aluno .p1 {
        width: 80%;
    }
    .body-pagamento-adote-um-aluno .accordion {
        width: 90%;
        margin-bottom: 4%;
    }
    .body-pagamento-adote-um-aluno .container-opcao{
        width: 90%;
        padding: 4% 4%;
    }
    .body-pagamento-adote-um-aluno .container-opcao div .boleto {
        width: 4vh;
    }
    .body-pagamento-adote-um-aluno .cartoes-credito img {
        width: 3.3vh !important;
    }
    .body-pagamento-adote-um-aluno span {
        font-size: 11px;
    }
    .centerflex{
        align-items: start;
        margin-bottom: 7%;
    }
    .centerflex img{
        width: 100%;
    }
    .summary {
        padding: 3vh 0 4vh 0;
    }
    .body-pagamento-adote-um-aluno .credito-formulario h3 {
        text-align: start;
        width: 100%;
    }
}
.body-portal{
    color: var(--primary-text-color);
    display: flex;
    flex-direction: column;
    align-items: center;
}



.portal-conteudo{
    width: 100%;
    height: 100vh;
    padding: 8% 0 0 4.5%;
}
.portal-conteudo h1{
    font-weight: 700;
    margin-bottom: 0;
    text-align: center;
    margin-bottom: 5%;
}
.portal-conteudo h3{
    margin-bottom: 1.5%;
}
.portal-conteudo ul{
    display: flex;
    align-items: center; 
    margin-bottom: 0;
}
.portal-conteudo ul svg{
    margin: 0 0.2% 0 1%; 
    font-size: 14px;
}
.portal-conteudo ul span{
    font-size: 14px;
    margin-top: 0.1%;
}
.portal-conteudo li a{
    color: var(--primary-color-3);
    transition: 0.6s ease-in;
}
.portal-conteudo li a:hover{
    text-decoration: underline;
}




/* celular e tablet */
@media screen and (max-width: 1080px) {
    .links-portal{
        width: 50%;
    }
    .links-portal img{
        width: 70px;
        margin: 0 4vw 0 0;
    }
    .links-portal a{
        display: none;
    }
    .botoes-header-portal{
        width: 45%;
    }
    .botoes-header-portal button{
        padding: 4% 4vw;
    }
    .portal-conteudo{
        padding: 30% 0 0 0%;
    }
    .portal-conteudo h1{
        padding: 0 3%;
    }
    .portal-conteudo div{
        margin-left: 5%;
    }
    .portal-conteudo ul svg{
        margin: 0 0.8% 0 3%; 
    }
}
.body-trocar-pagamento{
    padding: 7% 7% 1% 7% !important;
}
.trocar-pagamento h4{
  font-weight: 600;  
  margin-top: 2%;
}
.trocar-pagamento div{
    width: 55%;
    height: 6vh;
    display: flex;
    align-items: center;
    grid-gap: 2.5%;
    gap: 2.5%;
    margin-top: 2%;
}
.trocar-pagamento div button{
    background-color: var(--primary-color-2);
    height: 100%;
    border: 1px solid;
    border-color: var(--primary-color);
    border-radius: 4px;
    padding: 0 8% 0 3.6%;
    text-align: start;
    transition: 0.3s ease-in-out;
}
.trocar-pagamento .btn-active{
    border-color: var(--tertiary-color);
    -webkit-transform: scale(1.08);
            transform: scale(1.08);
}


.body-pagamento-online {
    background-color: var(--secondary-color);
    height: 100%;
    display: flex;
    flex-direction: column;
    color: var(--tertiary-color);
    padding: 7% 7% 4% 7%;
}

.body-pagamento-online nav {
    --bs-breadcrumb-divider: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8'%3E%3Cpath d='M2.5 0L1 1.5 3.5 4 1 6.5 2.5 8l4-4-4-4z' fill='%236c757d'/%3E%3C/svg%3E");
}

.body-pagamento-online nav ol li a {
    font-weight: 500;
}

.body-pagamento-online nav ol li {
    font-weight: 500;
}

.conteudo-pagamento {
    display: flex;
}

.conteudo-pagamento section:first-child {
    width: 55%;
    margin: 0% 0 3% 0;
    border-bottom: 1px solid;
    border-color: var(--tertiary-color);
}

.conteudo-pagamento section:first-child h1 {
    font-size: 26px;
    font-weight: 700;
    margin-bottom: 3%;
}

.body-pagamento-online .formulario-pagamento {
    margin: 0 0 0 0;
    background: #fff4fffd;
    border: 1px solid;
    border-color: var(--tertiary-color);
    padding: 0 2% 2% 3.5%;
    border-radius: 3px;
}

.formulario-pagamento section {
    width: 100% !important;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 2.5% 2% 2% 0;
}

.formulario-pagamento .section2 {
    width: 100% !important;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 2.5% 2% 2% 0;
}

.formulario-pagamento .section2 h5 {
    margin-bottom: 0;
    display: flex;
    align-items: center;
    white-space: nowrap;
    border-bottom: 1px solid;
    border-color: var(--tertiary-color);
    width: 100%;
    padding-bottom: 2%;
}

.formulario-pagamento section h5 {
    margin-bottom: 0;
    display: flex;
    align-items: center;
    white-space: nowrap;
}

.formulario-pagamento section h5 svg {
    margin-right: 0.5vw;
    font-size: 25px;
}

.formulario-pagamento section span {
    font-size: 11px;
}

.formulario-pagamento div {
    display: flex;
    flex-direction: column;
}

.parcela select {
    width: 5em !important;
    background-color: var(--primary-color);
    border: none;
    color: var(--primary-text-color);
    width: 5vw;
    padding-right: 10px;
    text-align: right;
    font-weight: 300;
}

.formulario-pagamento div input,
div select {
    margin-bottom: 1.7vh;
    background-color: transparent;
    border: 1px solid var(--tertiary-color);
    color: var(--primary-text-color);
    outline: none;
    padding: 1.5vh 1vw;
    border-radius: 5px;
    font-weight: 600;
}
.formulario-pagamento div input::-webkit-input-placeholder {
    color: var(--primary-text-color);
    font-weight: 400 !important;
}
.formulario-pagamento div input::placeholder {
    color: var(--primary-text-color);
    font-weight: 400 !important;
}

.formulario-pagamento div select option {
    color: var(--primary-text-color);
}

.formulario-pagamento div select option:hover {
    color: var(--secondary-text-color);
}


.conteudo-pagamento .section-detalhes {
    width: 45%;
    margin: 0;
    padding-left: 14%;
    padding-right: 5%;
}

.conteudo-pagamento .section-detalhes h5 {
    font-size: 26px;
    font-weight: 700;
}
.conteudo-pagamento .section-detalhes p{
    font-weight: 700;
    margin-bottom: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.conteudo-pagamento .section-detalhes p select{
    padding: 0 6px 0 0;
    margin-top: 12px;
}

.conteudo-pagamento .section-detalhes span {
    font-weight: 400;
}

.section-detalhes .total-diviser {
    width: 100% !important;
    margin-top: 1vh;
}

.conteudo-pagamento .section-detalhes div {
    display: flex;
}
.conteudo-pagamento .section-detalhes div label{
    font-size: 13px;
    margin: 5% 0 2% 0;
    color: var(--primary-text-color);
}
.conteudo-pagamento .section-detalhes div label input{
    margin-right: 1%;
}

.conteudo-pagamento .section-detalhes div label a {
    color: var(--primary-color);
    text-decoration: underline;
}
.section-detalhes .total span span{
    margin-right: 0.4vw;
}
.conteudo-pagamento .section-detalhes button {
    margin-bottom: 0px;
    padding: 2.5% 4%;
    width: 100%;
    margin-right: 0%;
    height: auto;
    border-radius: 5px;
    font-size: 13px;
    text-align: center;
    display: flex;
    justify-content: center;
}



.cupom-de-desconto{
    display: flex;
    flex-direction: column;
    margin: 8% 0 6% 0;
}
.cupom-de-desconto p{
    font-weight: 500 !important;
    margin-bottom: 1% !important;
}
.cupom-de-desconto div{
    width: 100%;
    display: flex;
    justify-content: space-between;
}
.cupom-de-desconto div input{
    text-transform: uppercase;
    background-color: transparent;
    border: 1px solid;
    border-color: var(--tertiary-color);
    border-radius: 3px;
    color: var(--primary-text-color);
    width: 68% !important;
    outline: 0;
    padding-left: 3%;
    font-size: 13px;
}
.cupom-de-desconto div button{
    width: 30% !important;
    background-color: var(--green-color);
    border: none;
    padding: 0.8vh 1vw;
    border-radius: 4px !important;
    font-weight: 500;
    box-shadow: 1px 1px 6px var(--tertiary-color);
    transition: 0.4s ease-in-out;
    font-size: 13px;
    text-align: center;
}
.cupom-de-desconto div button:hover{
    background-color: var(--2-green-color);
}
.botao-remover{
    background-color: var(--dark-red-color) !important;
}
.botao-remover:hover{
    background-color: var(--2-dark-red-color) !important;
}
.alinhamento-preco{
    display: flex;
    align-items: center;
}
.perc-desconto{
    color: var(--dark-red-color);
    font-size: 13px;
    display: flex;
    justify-content: end;
    margin-right: 6px;
    font-weight: 600 !important;
}









/* celular e tablet */
@media screen and (max-width: 1080px) {
    .body-pagamento-online {
        padding: 28% 7% 20% 7%;
    }
    .conteudo-pagamento {
        flex-direction: column;
    }
    .conteudo-pagamento section:first-child {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: start;
    }
    .formulario-pagamento section {
        padding: 4% 2% 4% 0;
    }
    .formulario-pagamento .section2 h5 {
        padding-bottom: 4%;
    }
    .formulario-pagamento section h5 svg {
        margin-right: 2vw;
        margin-bottom: 1%;
    }
    .conteudo-pagamento .section-detalhes {
        width: 100%;
        margin: 10% 0 0 0;
        padding-left: 6%;
    }
    .formulario-pagamento div input, div select {
        padding: 1.5vh 3vw;
    }
    .body-trocar-pagamento{
        padding: 28% 7% 5% 7% !important;
    }
    .trocar-pagamento div{
        width: 100%;
        grid-gap: 6%;
        gap: 6%;
        margin-top: 6%;
    }
    .trocar-pagamento .btn-active{
        -webkit-transform: scale(1.1);
                transform: scale(1.1);
    }
}
.body-termos{
    height: 100%;
    width: 100%;
    color: var(--primary-text-color);
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 5% 25%;
    background-color: var(--secondary-color);
}
.body-termos h1{
    font-size: 32px;
    font-weight: 700;
}
.body-termos h2{
    font-size: 23px;
    font-weight: 600;
    margin-top: 2%;
}
.body-termos p{
    text-align: justify;
}
.body-administrativo{
    background-color: var(--background-color);
    height: 100%;
    display: flex;
    flex-direction: column;
    color: var(--white-color);
}
.body-administrativo main{
    padding-top: 8%;
    padding-bottom: 8%;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 90%;
    margin-left: 5%;
}

.body-administrativo .breadcrumb{
    width: 90vw;
    --bs-breadcrumb-divider: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8'%3E%3Cpath d='M2.5 0L1 1.5 3.5 4 1 6.5 2.5 8l4-4-4-4z' fill='%236c757d'/%3E%3C/svg%3E");
}
.body-administrativo .breadcrumb ol li a{
    color: var(--white-color);
    text-decoration: none;
    font-weight: 500;
}
.body-administrativo .breadcrumb ol li{
    font-weight: 500;
}

.body-administrativo main form{
    display: flex;
    align-items: center;
    justify-content: flex-end;
    grid-gap: 4%;
    gap: 4%;
    width: 100%;
    margin-bottom: 2%;
}
.body-administrativo main form div{
    display: flex;
    align-items: center;
}
.body-administrativo main form label{
    margin-right: 1vw;
    margin-top: -10px;
}
.body-administrativo main form input{
    margin-bottom: 1.7vh;
    background-color: transparent;
    border: 1px solid var(--white-color);
    color: var(--white-color);
    outline: none;
    padding: 1.5% 5%;
    border-radius: 5px;
}
.body-administrativo main form select{
    padding: 1.5% 1%;
    cursor: pointer;
}
.body-administrativo main form option{
    color: var(--black-color);
}
.body-administrativo main form button{
    background-color: var(--dark-blue-color);
    border: none;
    padding: 0.6vh 1.5vw;
    font-weight: 600;
    border-radius: 5px;
    font-size: 0.8rem;
    box-shadow: 1px 1px 8px #00000082;
    transition: 0.4s ease-in-out;
    margin-top: -14px;
}
.body-administrativo main form button:hover{
    background-color: var(--2-dark-blue-color);
}

.body-administrativo .table-container{
    width: 100%;
}
.body-administrativo main table tbody td{
    white-space: nowrap;
}
.body-administrativo main table tbody svg{
    font-size: 18px;
    margin-right: 0.5vw;
    cursor: pointer;
}

.body-administrativo .select-espera{
    width: 100%;
    display: flex;
    justify-content: flex-end;
    margin-bottom: 2%;
}
.body-administrativo .select-espera select{
    padding: 0.5% 0.5%;
    cursor: pointer;
}
.body-administrativo .select-espera select option{
    color: var(--black-color);
}
.body-administrativo .tbody-espera button{
    padding: 3% 10%;
}


.body-editar{
    margin-top: 6%;
}
.btn-editar{
    width: 100%;
    display: flex;
    justify-content: flex-end;
}
.body-detalhes{
    padding-bottom: 0% !important;
}

.box-cases{
    display: flex;
    width: 90vw;
    justify-content: space-between;
    margin-top: 5%;
    flex-wrap: wrap;
    grid-gap: 5%;
    gap: 5%;
}
.box-cases div{
    display: flex;
    flex-direction: column;
    width: 20%;
    margin-bottom: 5%;
}

.adm-input-img{
    width: 300px;
    min-width: 300px;
    max-width: 300px;
    height: 150px;
    min-height: 150px;
    max-height: 150px;
    border-radius: 10%;
    border: 3px solid var(--border-gray-color);
    margin-bottom: 4px;
}
.adm-input-svg{
    z-index: 99;
    position: absolute;
    margin-left: 3.2%;
    margin-top: 5%;
    opacity: 0.9;
    transition: all 0.5s;
    cursor: pointer;
}
.adm-input-svg:hover{
    opacity: 1;
    -webkit-transform: scale(1.3);
            transform: scale(1.3);
}
.adm-input{
    width: 30vw;
    border: 1px solid var(--1-gray-color);
    border-radius: 4px;
    cursor: pointer;
    padding: 5px 9px;
}



/* celular e tablet */
@media screen and (max-width: 1080px){
    .body-administrativo{
        width: 100%;
    }
    .body-administrativo main{
        padding-top: 30%;
        padding-bottom: 20%;
    }
    .body-administrativo main form{
        flex-direction: column;
        align-items: start;
        grid-gap: 6%;
        gap: 6%;
        margin-bottom: 2%;
    }
    .body-administrativo main form div{
        align-items: start;
        flex-direction: column;
    }
    .body-administrativo main form label{
        margin: 0 0 3% 0;
    }
    .body-administrativo main form input{
        margin-bottom: 1vh;
        padding: 1.5% 6%;
    }
    .body-administrativo main form select{
        padding: 2.5% 3%;
    }
    .body-administrativo main form button{
        padding: 0.8vh 4.5vw;
        font-size: 0.9rem;
        margin-top: 2%;
        margin-bottom: 5%;
    }
    .body-administrativo main table tbody svg{
        font-size: 22px;
        margin-right: 1vw;
    }
    .table-container {
        width: 100vw;
        overflow-x: auto; 
    }
    .body-administrativo table {
        width: 100%;
        border-collapse: collapse;
    }
    .body-administrativo .select-espera{
        justify-content: flex-start;
        margin-bottom: 3%;
        margin-top: 2%;
    }
    .body-administrativo .select-espera select{
        padding: 1.5% 3%;
    }
    .body-editar{
        margin-top: 0%;
        width: 90%;
    }
    .body-detalhes{
        width: 85% !important;
        padding-left: 5% !important;
        padding-bottom: 12% !important;
    }
}
.body-certificados{
    padding-top: 8%;
    width: 80%;
    margin-left: 10%;
}
.body-certificados h2{
    text-align: center;
    margin-top: 6%;
    color: var(--primary-text-color);
    font-size: 22px;
}
.body-certificados h3{
    color: var(--primary-text-color);
    font-size: 18px;
    margin: 2% 0;
}
.body-certificados nav{
    --bs-breadcrumb-divider: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8'%3E%3Cpath d='M2.5 0L1 1.5 3.5 4 1 6.5 2.5 8l4-4-4-4z' fill='%236c757d'/%3E%3C/svg%3E");
}
.body-certificados nav ol li a{
    color: var(--primary-text-color);
    text-decoration: none;
    font-weight: 500;
}
.body-certificados nav ol li{
    font-weight: 500;
}


.card-certificado{
    background-color: var(--primary-color-4);
    display: flex;
    align-items: start;
    justify-content: space-between;
    border-radius: 8px;
    padding: 3%;
    margin-bottom: 3%;
    color: var(--secondary-text-color);
}
.card-certificado img{
    width: 130px;
}
.card-certificado div{
    width: 63%;
}
.card-certificado div h6{
    font-size: 18px;
    font-weight: 700;
}
.card-certificado div p{
    margin-bottom: 1%;
}
.card-certificado div span{
    font-weight: 600;
}
.card-certificado button{
    white-space: nowrap;
    font-weight: 600;
    font-size: 14px;
    margin-top: 2%;
}




/* celular e tablet */
@media screen and (max-width: 1080px){
    .body-certificados{
        padding-top: 30%;
        width: 90%;
        margin-left: 5%;
    }
    .body-certificados h2{
        margin-top: 16%;
    }
    .body-certificados h3{
        margin: 8% 0;
    }

    .card-certificado{
        padding: 7%;
        margin-bottom: 7%;
        flex-direction: column;
    }
    .card-certificado img{
       display: none;
    }
    .card-certificado div{
        width: 100%;
    }
    .card-certificado div p{
        display: none;
    }
    .card-certificado button{
        margin-top: 10%;
    }
}
.body-add-curso {
    background-color: var(--background-color);
    padding-top: 8%;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100vh;
    color: var(--white-color);
}

.body-add-aula {
    background-color: var(--background-color);
    padding-top: 8%;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100vh;
    color: var(--white-color);
}

.body-add-aula table {
    width: 80%;
}

.body-buttons {
    display: flex;
    grid-gap: 20px;
    gap: 20px;
    margin: 15px;    
}
.centerSwitch{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
}
.body-form-curso {
    background-color: var(--background-color);
    padding-top: 8%;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: var(--white-color);
}

.body-form-curso .left {
    align-self: flex-start;
    margin: 30px;
    margin-left: 22%;
}

.box-trilha-conteudo-modulo {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}


.body-form-curso textarea {
    width: 100%;
    height: 20vh;
    max-height: 20vh;
    background-color: white;
    border-radius: 10px;
    padding: 5px;

}

.body-add-curso .article-trilha {
    padding-top: 3%;
}

.article-trilha-modulo {
    width: 70%;
    display: flex;
    flex-wrap: wrap;
    grid-gap: 20px;
    gap: 20px;
    justify-content: center;
    margin-top: 2%;

}

.article-trilha-modulo article {
    min-width: 400px;
    max-width: 50%;
}

.body-buttons .btn-add {
    background-color: #03045e;
    border: none;
    padding: 1vh 1.5vw;
    font-weight: 600;
    border-radius: 5px;
    font-size: 0.8rem;
    box-shadow: 1px 1px 8px #00000082;
    transition: 0.4s ease-in-out;
    width: 190px;
    text-align: center;
    cursor: pointer;
    position: relative;
    right: 30px;
}

.body-buttons .btn-addd {
    align-self: flex-end;

}

.body-buttons .btn-add:hover {
    background-color: #010497b4;
}

.body-buttons .btn-add svg {
    font-size: 20px;
}

.body-form-curso input{
    border-radius: 20px;
    margin-bottom: 20px;
}

.body-finalizar-pix{
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--primary-text-color);
    flex-direction: column;
    height: 100vh;
}
.body-finalizar-pix h4{
    font-weight: 600;
    margin-bottom: 1%;
    display: flex;
    align-items: center;
    white-space: nowrap;
}
.body-finalizar-pix h4 img{
    width: 35px;
    margin-left: 3%;
}
.body-finalizar-pix .div-code{
    width: 35%;
    background-color: var(--primary-color-3);
    border: 1px solid;
    border-color: var(--tertiary-color);
    border-radius: 5px;
    padding: 2%;
}
.body-finalizar-pix .div-code img{
    width: 160px;
    border-radius: 6px;
    margin: 4% 0 6% 30%;
}
.body-finalizar-pix .div-code h6{
    font-weight: 600;
    font-size: 17px;
}

.body-finalizar-pix .div-code div{
    border: 1px solid;
    border-color: var(--tertiary-color);
    padding: 2% 3%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 4px;
    margin: 4% 0;
}
.body-finalizar-pix .div-code div span{
    font-weight: 600;
    color: var(--border-gray-color);
}
.body-finalizar-pix .div-code div p{
    cursor: pointer;
    margin-bottom: 0;
    font-size: 15px;
}
.body-finalizar-pix .div-code section {
    border-top: 1px solid;
    border-color: var(--tertiary-color);
    padding-top: 5%;
    margin-top: 5%;
}
.body-finalizar-pix .div-code section p{
    font-size: 15px;
}
.body-finalizar-pix .div-code section p a{
    color: var(--primary-color-1);
    text-decoration: underline;
}



/* celular e tablet */
@media screen and (max-width: 1080px) {
    .body-finalizar-pix{
        padding: 30% 0 5% 0;
    }
    .body-finalizar-pix h4{
        margin-bottom: 8%;
        white-space: wrap;
        display: block;
        width: 80%;
        text-align: center;
    }
    .body-finalizar-pix h4 img{
        width: 30px;
        margin-left: 2%;
    }
    .body-finalizar-pix .div-code{
        width: 90%;
        padding: 4%;
    }
    .body-finalizar-pix .div-code img{
        margin: 4% 0 6% 25%;
    }
    .body-finalizar-pix .div-code div span{
        font-size: 14px;
    }
    .body-finalizar-pix .div-code div button{
        font-size: 14px;
    }
    .body-finalizar-pix .div-code section {
        padding-top: 7%;
        margin-top: 7%;
    }
}
.body-add-aula{
    background-color: var(--background-color);
    height: 100%;
    display: flex;
    flex-direction: column;
    color: var(--white-color);
    width: 100%;
    padding-top: 9%;
}
  
.drag-container {
    width: 100%;
}
  
.drag-container td {
    padding: 10px;
    border-bottom: 1px solid var(--white-color);
}

.drag-container{
    width: 30vw !important;
}
  
  

