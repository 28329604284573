.body-certificados{
    padding-top: 8%;
    width: 80%;
    margin-left: 10%;
}
.body-certificados h2{
    text-align: center;
    margin-top: 6%;
    color: var(--primary-text-color);
    font-size: 22px;
}
.body-certificados h3{
    color: var(--primary-text-color);
    font-size: 18px;
    margin: 2% 0;
}
.body-certificados nav{
    --bs-breadcrumb-divider: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8'%3E%3Cpath d='M2.5 0L1 1.5 3.5 4 1 6.5 2.5 8l4-4-4-4z' fill='%236c757d'/%3E%3C/svg%3E");
}
.body-certificados nav ol li a{
    color: var(--primary-text-color);
    text-decoration: none;
    font-weight: 500;
}
.body-certificados nav ol li{
    font-weight: 500;
}


.card-certificado{
    background-color: var(--primary-color-4);
    display: flex;
    align-items: start;
    justify-content: space-between;
    border-radius: 8px;
    padding: 3%;
    margin-bottom: 3%;
    color: var(--secondary-text-color);
}
.card-certificado img{
    width: 130px;
}
.card-certificado div{
    width: 63%;
}
.card-certificado div h6{
    font-size: 18px;
    font-weight: 700;
}
.card-certificado div p{
    margin-bottom: 1%;
}
.card-certificado div span{
    font-weight: 600;
}
.card-certificado button{
    white-space: nowrap;
    font-weight: 600;
    font-size: 14px;
    margin-top: 2%;
}




/* celular e tablet */
@media screen and (max-width: 1080px){
    .body-certificados{
        padding-top: 30%;
        width: 90%;
        margin-left: 5%;
    }
    .body-certificados h2{
        margin-top: 16%;
    }
    .body-certificados h3{
        margin: 8% 0;
    }

    .card-certificado{
        padding: 7%;
        margin-bottom: 7%;
        flex-direction: column;
    }
    .card-certificado img{
       display: none;
    }
    .card-certificado div{
        width: 100%;
    }
    .card-certificado div p{
        display: none;
    }
    .card-certificado button{
        margin-top: 10%;
    }
}