.card-container {
    height: 410px;
    width: 300px;
    perspective: 1000px;
    margin: 0.5rem;
    position: relative;
    margin-bottom: 100px;
}

.card-container .card-front, 
.card-container .card-back {
    height: 100%;
    width: 100%;
    position: absolute;
    backface-visibility: hidden;
    transform-style: preserve-3d;
    transition: transform 0.6s ease;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #9075B0;
    padding: 1rem;
    box-sizing: border-box;
}

.card-front {
    transform: rotateY(0deg);
}

.card-back {
    transform: rotateY(180deg);
}

.card-container.is-flipped .card-front {
    transform: rotateY(180deg);
}

.card-container.is-flipped .card-back {
    transform: rotateY(0deg);
}

.card-img {
    width: 50%;
}

.card-title {
    margin: 0.5rem 5%;
    text-align: center;
}

.toggle-images-btn {
    text-align: center;
    width: 90%;
    border-radius: 7px;
    padding: 0.5rem 1rem;
    background-color: #614a80;
    color: white;
    text-decoration: none;
    margin: 0.5rem;
    cursor: pointer;
}

.images-container {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 1rem;
    width: 100%;
    align-items: center;
}

.container-img-item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 5px;
}

.image-item {
    width: 40px;
    height: 40px;
}


