.body-add-aula{
    background-color: var(--background-color);
    height: 100%;
    display: flex;
    flex-direction: column;
    color: var(--white-color);
    width: 100%;
    padding-top: 9%;
}
  
.drag-container {
    width: 100%;
}
  
.drag-container td {
    padding: 10px;
    border-bottom: 1px solid var(--white-color);
}

.drag-container{
    width: 30vw !important;
}
  
  