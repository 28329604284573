.body-add-curso {
    background-color: var(--background-color);
    padding-top: 8%;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100vh;
    color: var(--white-color);
}

.body-add-aula {
    background-color: var(--background-color);
    padding-top: 8%;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100vh;
    color: var(--white-color);
}

.body-add-aula table {
    width: 80%;
}

.body-buttons {
    display: flex;
    gap: 20px;
    margin: 15px;    
}
.centerSwitch{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
}
.body-form-curso {
    background-color: var(--background-color);
    padding-top: 8%;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: var(--white-color);
}

.body-form-curso .left {
    align-self: flex-start;
    margin: 30px;
    margin-left: 22%;
}

.box-trilha-conteudo-modulo {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}


.body-form-curso textarea {
    width: 100%;
    height: 20vh;
    max-height: 20vh;
    background-color: white;
    border-radius: 10px;
    padding: 5px;

}

.body-add-curso .article-trilha {
    padding-top: 3%;
}

.article-trilha-modulo {
    width: 70%;
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: center;
    margin-top: 2%;

}

.article-trilha-modulo article {
    min-width: 400px;
    max-width: 50%;
}

.body-buttons .btn-add {
    background-color: #03045e;
    border: none;
    padding: 1vh 1.5vw;
    font-weight: 600;
    border-radius: 5px;
    font-size: 0.8rem;
    box-shadow: 1px 1px 8px #00000082;
    transition: 0.4s ease-in-out;
    width: 190px;
    text-align: center;
    cursor: pointer;
    position: relative;
    right: 30px;
}

.body-buttons .btn-addd {
    align-self: flex-end;

}

.body-buttons .btn-add:hover {
    background-color: #010497b4;
}

.body-buttons .btn-add svg {
    font-size: 20px;
}

.body-form-curso input{
    border-radius: 20px;
    margin-bottom: 20px;
}
