.body-administrativo{
    background-color: var(--background-color);
    height: 100%;
    display: flex;
    flex-direction: column;
    color: var(--white-color);
}
.body-administrativo main{
    padding-top: 8%;
    padding-bottom: 8%;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 90%;
    margin-left: 5%;
}

.body-administrativo .breadcrumb{
    width: 90vw;
    --bs-breadcrumb-divider: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8'%3E%3Cpath d='M2.5 0L1 1.5 3.5 4 1 6.5 2.5 8l4-4-4-4z' fill='%236c757d'/%3E%3C/svg%3E");
}
.body-administrativo .breadcrumb ol li a{
    color: var(--white-color);
    text-decoration: none;
    font-weight: 500;
}
.body-administrativo .breadcrumb ol li{
    font-weight: 500;
}

.body-administrativo main form{
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 4%;
    width: 100%;
    margin-bottom: 2%;
}
.body-administrativo main form div{
    display: flex;
    align-items: center;
}
.body-administrativo main form label{
    margin-right: 1vw;
    margin-top: -10px;
}
.body-administrativo main form input{
    margin-bottom: 1.7vh;
    background-color: transparent;
    border: 1px solid var(--white-color);
    color: var(--white-color);
    outline: none;
    padding: 1.5% 5%;
    border-radius: 5px;
}
.body-administrativo main form select{
    padding: 1.5% 1%;
    cursor: pointer;
}
.body-administrativo main form option{
    color: var(--black-color);
}
.body-administrativo main form button{
    background-color: var(--dark-blue-color);
    border: none;
    padding: 0.6vh 1.5vw;
    font-weight: 600;
    border-radius: 5px;
    font-size: 0.8rem;
    box-shadow: 1px 1px 8px #00000082;
    transition: 0.4s ease-in-out;
    margin-top: -14px;
}
.body-administrativo main form button:hover{
    background-color: var(--2-dark-blue-color);
}

.body-administrativo .table-container{
    width: 100%;
}
.body-administrativo main table tbody td{
    white-space: nowrap;
}
.body-administrativo main table tbody svg{
    font-size: 18px;
    margin-right: 0.5vw;
    cursor: pointer;
}

.body-administrativo .select-espera{
    width: 100%;
    display: flex;
    justify-content: flex-end;
    margin-bottom: 2%;
}
.body-administrativo .select-espera select{
    padding: 0.5% 0.5%;
    cursor: pointer;
}
.body-administrativo .select-espera select option{
    color: var(--black-color);
}
.body-administrativo .tbody-espera button{
    padding: 3% 10%;
}


.body-editar{
    margin-top: 6%;
}
.btn-editar{
    width: 100%;
    display: flex;
    justify-content: flex-end;
}
.body-detalhes{
    padding-bottom: 0% !important;
}

.box-cases{
    display: flex;
    width: 90vw;
    justify-content: space-between;
    margin-top: 5%;
    flex-wrap: wrap;
    gap: 5%;
}
.box-cases div{
    display: flex;
    flex-direction: column;
    width: 20%;
    margin-bottom: 5%;
}

.adm-input-img{
    width: 300px;
    min-width: 300px;
    max-width: 300px;
    height: 150px;
    min-height: 150px;
    max-height: 150px;
    border-radius: 10%;
    border: 3px solid var(--border-gray-color);
    margin-bottom: 4px;
}
.adm-input-svg{
    z-index: 99;
    position: absolute;
    margin-left: 3.2%;
    margin-top: 5%;
    opacity: 0.9;
    transition: all 0.5s;
    cursor: pointer;
}
.adm-input-svg:hover{
    opacity: 1;
    transform: scale(1.3);
}
.adm-input{
    width: 30vw;
    border: 1px solid var(--1-gray-color);
    border-radius: 4px;
    cursor: pointer;
    padding: 5px 9px;
}



/* celular e tablet */
@media screen and (max-width: 1080px){
    .body-administrativo{
        width: 100%;
    }
    .body-administrativo main{
        padding-top: 30%;
        padding-bottom: 20%;
    }
    .body-administrativo main form{
        flex-direction: column;
        align-items: start;
        gap: 6%;
        margin-bottom: 2%;
    }
    .body-administrativo main form div{
        align-items: start;
        flex-direction: column;
    }
    .body-administrativo main form label{
        margin: 0 0 3% 0;
    }
    .body-administrativo main form input{
        margin-bottom: 1vh;
        padding: 1.5% 6%;
    }
    .body-administrativo main form select{
        padding: 2.5% 3%;
    }
    .body-administrativo main form button{
        padding: 0.8vh 4.5vw;
        font-size: 0.9rem;
        margin-top: 2%;
        margin-bottom: 5%;
    }
    .body-administrativo main table tbody svg{
        font-size: 22px;
        margin-right: 1vw;
    }
    .table-container {
        width: 100vw;
        overflow-x: auto; 
    }
    .body-administrativo table {
        width: 100%;
        border-collapse: collapse;
    }
    .body-administrativo .select-espera{
        justify-content: flex-start;
        margin-bottom: 3%;
        margin-top: 2%;
    }
    .body-administrativo .select-espera select{
        padding: 1.5% 3%;
    }
    .body-editar{
        margin-top: 0%;
        width: 90%;
    }
    .body-detalhes{
        width: 85% !important;
        padding-left: 5% !important;
        padding-bottom: 12% !important;
    }
}