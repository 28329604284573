.body-comentarios{
    height: 86%;
    overflow-y: auto;
}

.body-comentarios .publicar{
    display: flex;
    flex-direction: column;
    width: 100%;
    border-bottom: 1px solid var(--tertiary-color);
}
.body-comentarios .publicar textarea{
    margin: 5% 5%;
    border: 1px solid var(--tertiary-color);
    background-color: transparent;
    font-size: 14px;
    color: var(--primary-text-color);
    height: 90px;
    border-radius: 5px;
    padding: 5px;
}  
.body-comentarios button{
    background-color: var(--primary-color);
    color: var(--primary-text-color);
    border: none;
    padding: 4px 0;
    border-radius: 5px;
    font-size: 13px;
    margin-bottom: 5%;
    margin-left: 65%;
    width: 30%;
    transition: 0.3s ease-in;
    font-weight: 600;
}
.body-comentarios button:hover{
    background-color: var(--primary-color-2);
}

.box-comentario{
    border-bottom: 1px solid var(--tertiary-color);
    padding: 10px 16px;
}

.box-comentario h6{
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 16px;
    font-weight: 700;
}

.box-comentario span{
    font-size: 11px;
    margin-top: 2px;
    font-weight: 500;
}

.box-comentario p{
    font-weight: 400;
    font-size: 14px;
}