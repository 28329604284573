body {
    background-color: var(--secondary-color);
}

.article-trilha {
    padding: 9% 0 2% 0;
    background-color: var(--secondary-color);
}

.body-trilha {
    background-color: var(--secondary-color);
    height: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    color: var(--secondary-text-color);
    padding-bottom: 5%;
}

.box-trilha {
    background-color: #7561be;
    border-radius: 10px;
    height: 40vh;
    width: 80%;
    display: flex;
    transition: 0.4s ease-in-out;
    color: var(--secondary-text-color);
    text-decoration: none;
}

.box-trilha:hover {
    transform: scale(1.03);
    z-index: 1;
    cursor: pointer;
}

.box-trilha img {
    width: 19%;
    height: 77%;
    margin: 30px 30px;
    margin-left: 30px;
    margin-right: 30px;
    border-radius: 10px;
}

.box-trilha-conteudo {
    margin-top: 30px;
    display: flex;
    flex-direction: column;
    max-width: 40%;
    min-width: 40%;
}

.box-trilha-conteudo h1 {
    font-size: 30px;
    font-weight: 600;
}

.box-trilha-conteudo p {
    max-width: 85%;
    font-size: 15px;
}

.box-trilha-box {
    margin-top: 30px;
    display: flex;
    flex-direction: column;
    max-width: 30%;
    min-width: 30%;
    background-color: var(--secondary-color);
    border-radius: 10px;
    padding: 20px 0 20px 20px;
    height: 80%;
    color: var(--primary-text-color);
    font-weight: 600;
}

.div-trilha-itens {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
}

.div-trilha-item {
    width: 50%;
    display: flex;
    margin: 10px 0;
    align-items: center;
}

.div-trilha-item svg {
    font-size: 25px;
    margin-right: 10px;
}

.div-trilha-item p {
    margin-bottom: 0;
    font-size: 13px;
}

.div-trilha-item span {
    font-size: 12px;
    font-weight: 600;
}

.div-progress {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
}

.div-progress span {
    margin-left: 10px;
    font-size: 11px;
    font-weight: 500;
}

.heigth-progress {
    height: 10px;
    width: 60%;
}

.loading {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    border-left:10px solid rgba(0, 0, 0, 0);
    border-right:10px solid rgba(0, 0, 0, 0);
    border-top: 10px solid var(--secondary-color);
    border-bottom: 10px solid var(--secondary-color);
    border-radius: 100%;
    width: 10vh;
    height: 10vh;
    animation: rotacao linear infinite 0.5s;
}

@keyframes rotacao{
    0%{
        rotate: 0deg;
    }
    100%{
        rotate: 360deg;
    }
}







/* celular */
@media screen and (max-width: 767px){
    .article-trilha {
        padding: 30% 0 10% 0;
    }
    .box-trilha {
        border-radius: 8px;
        height: 40vh;
        width: 90%;
        flex-wrap: wrap;
    }
    .box-trilha img {
        width: 30%;
        height: 30%;
        margin: 20px 20px;
        border-radius: 8px;
    }
    .box-trilha-conteudo {
        margin-top: 20px;
        max-width: 55%;
        min-width: 55%;
    }
    .box-trilha-conteudo h1 {
        font-size: 19px;
        font-weight: 700;
    }
    .box-trilha-conteudo p{
        max-width: 95%;
        text-align: justify;
        font-size: 9px;
    }
    .box-trilha-box {
        margin-top: -12%;
        max-width: 88%;
        min-width: 88%;
        margin-left: 6%;
        border-radius: 8px;
        padding: 15px 0 0 15px;
        height: 45%;
    }
    .div-trilha-item span {
        font-size: 11px;
    }
}







/* tablet */
@media screen and (min-width: 768px) and (max-width: 1150px){
    .article-trilha {
        padding: 20% 0 10% 0;
    }
    .box-trilha {
        border-radius: 8px;
        height: 40vh;
        width: 90%;
        flex-wrap: wrap;
    }
    .box-trilha img {
        width: 30%;
        height: 44%;
        margin: 30px 30px;
        border-radius: 8px;
    }
    .box-trilha-conteudo {
        margin-top: 30px;
        max-width: 58%;
        min-width: 58%;
    }
    .box-trilha-conteudo h1 {
        font-size: 22px;
        font-weight: 700;
    }
    .box-trilha-conteudo p{
        max-width: 80%;
        text-align: justify;
        font-size: 14px;
    }
    .box-trilha-box {
        margin-top: -6%;
        max-width: 50%;
        min-width: 50%;
        margin-left: 4.2%;
        border-radius: 8px;
        padding: 15px 0 0 25px;
        height: 36%;
    }
    .div-trilha-item span {
        font-size: 12px;
    }
}