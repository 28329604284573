.disabled {
    text-align: left;
}

.disabled span {
    margin-left: 1vw;
    font-size: 12px;
    display: flex;
    align-items: center;
    white-space: nowrap;
}

.disabled span svg {
    margin-right: 0.3vw;
    font-size: 18px;
}

.body-pagamento-doar {
    padding: 0 0 0 !important;
    height: 100%;
}
.body-pagamento-doar .accordion{
    padding-bottom: 8%;
}
.body-pagamento-doar .cadeado{
    margin-top: 10%;
}

.body-pagamento-doar .accordion .accordion-item{
    margin-bottom: 1%;
}

.body-pagamento-doar .accordion .accordion-item #collapseThree .accordion-body{
    padding: 5% 4% 6% 4% !important;
    box-shadow: 1px 2px 5px var(--tertiary-color);
}

.body-pagamento-doar .accordion .accordion-item #collapseThree .accordion-body
.credito-formulario button{
    margin-top: 2%;
}

.body-pagamento-doar .accordion-item:last-of-type .accordion-button.collapsed {
    border-bottom-right-radius: 6px !important;
    border-bottom-left-radius: 6px !important; 
}


.body-pagamento-doar .cartoes-credito {
    display: flex;
    gap: 0.6vw;
    justify-content: start;
    margin-left: 2%;
}

.body-pagamento-doar .cartoes-credito img {
    width: 5vh !important;
}

.credito-formulario .formulario-pagamento button {
    background-color: var(--primary-color-3);
    color: var(--primary-text-color);
    border: none;
    padding: 1vh 7px;
    width: 100%;
    border-radius: 7px;
    cursor: pointer;
    font-size: 16px;
    font-weight: 600;
    box-shadow: 1px 1px 6px var(--tertiary-color);
    transition: 0.3s ease-in-out;
}

.componente-credito .credito-formulario .formulario-pagamento button:hover {
    background-color: var(--primary-color);
}

.componente-credito .credito-formulario .formulario-pagamento .summary {
    margin-top: 5%;
}

.credito-formulario .formulario-pagamento {
    margin: 4% 0 0 0;
}

.componente-credito .credito-formulario .formulario-pagamento div {
    display: flex;
    flex-direction: column;
}

.componente-credito .credito-formulario .formulario-pagamento div input,
div select {
    margin-bottom: 1.7vh;
    background-color: transparent;
    border: 1px solid var(--tertiary-color);
    color: var(--primary-text-color);
    outline: none;
    padding: 1.5vh 1vw;
    border-radius: 5px;
}

.formulario-pagamento div select option {
    color: var(--primary-text-color);
}

.formulario-pagamento div select option:hover {
    color: var(--tertiary-color);
}

#accordionExample {
    overflow-y: visible !important;
}

.credito-formulario {
    margin: 2% 0 0.5% 0;
    width: 100%;

}

.credito-formulario span {
    font-size: 13px;
}

.credito-formulario .formulario-pagamento {
    margin: 4% 0 0 0;
}

.pagamento-doar-valor{
    display: flex !important;
    flex-direction: row !important;
    align-items: center !important;
    border: 1px solid var(--tertiary-color);
    border-radius: 5px;
    padding: 0 1vw;
    gap: 0.5vw;
}
.pagamento-doar-valor input{
    border: none !important;
    padding: 0 !important;
    margin-top: 1.5vh !important;
}


.credito-formulario .formulario-pagamento div {
    display: flex;
    flex-direction: column;
}

.credito-formulario .formulario-pagamento div input,
div select {
    margin-bottom: 1.7vh;
    background-color: transparent;
    border: 1px solid var(--tertiary-color);
    color: var(--primary-text-color);
    outline: none;
    padding: 1.5vh 1vw;
    border-radius: 5px;
}

.body-pagamento-doar {
    color: var(--primary-text-color);
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 5% 0;
}

.body-pagamento-doar .cadeado {
    font-size: 60px;
    border: 2px solid var(--tertiary-color);
    padding: 8px;
    border-radius: 50%;
    margin-bottom: 1%;
}

.body-pagamento-doar h3 {
    font-weight: 700;
}
.body-pagamento-doar .credito-formulario h3 {
    margin-bottom: 3%;
}

.body-pagamento-doar .p1 {
    width: 30%;
    text-align: center;
}


.body-pagamento-doar .accordion {
    width: 45%;
    margin-bottom: 1%;
    overflow-y: hidden !important;
}

.body-pagamento-doar .accordion-header .accordion-button {
    background-color: var(--secondary-color) !important;
    border: 2px solid var(--tertiary-color) !important;
    border-radius: 6px;
    padding-left: 2vw;
    transition: 0.2s ease-in;
}

.accordion-button p {
    padding-left: 0 !important;
}


.body-pagamento-doar .accordion-header .accordion-button:hover {
    background-color: var(--header-secundary-color) !important;
}

.body-pagamento-doar .accordion-header {
    background-color: var(--secondary-color) !important;
}

.body-pagamento-doar .accordion-header div {
    display: flex;
    align-items: center;
}

.body-pagamento-doar .accordion-header div p {
    margin-bottom: 0;
    padding: 5% 0 5% 10%;
}

.body-pagamento-doar .accordion-header div .pix {
    width: 22%;
    margin-left: 8%;
}

.body-pagamento-doar .accordion-body {
    display: flex;
    align-items: center;
    width: 100%;
    padding: 6% 4% !important;
    cursor: auto;
    box-shadow: 1px 2px 5px var(--tertiary-color);
}

.body-pagamento-doar .accordion-body img {
    width: 210px;
    /* height: auto; */
    margin-right: 5%;
    border-radius: 8px;
}

.body-pagamento-doar .accordion-body div p {
    width: 100%;
    margin-bottom: 2vh !important;
    font-size: 15.3px;
}

.body-pagamento-doar .accordion-body div p span {
    font-size: 15.3px;
    font-weight: 600;
}

.body-pagamento-doar .accordion-body div p a {
    text-decoration: underline;
    color: var(--primary-color);
    font-weight: 600;
}

.body-pagamento-doar .accordion-button:not(.collapsed)::after {
    background-image: url("../../imagens/icon/top.png") !important;
    margin-right: 1vw !important;
}

.accordion-button::after {
    background-image: url("../../imagens/icon/top.png") !important;
    margin-right: 1vw !important;
}


.body-pagamento-doar .container-opcao {
    width: 45%;
    border: 2px solid var(--tertiary-color);
    margin-bottom: 1%;
    border-radius: 6px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1.5% 2%;
    transition: 0.2s ease-in;
}

.body-pagamento-doar .container-opcao:hover {
    background-color: var(--header-secundary-color);
}

.body-pagamento-doar .container-opcao svg {
    font-size: 22px;
}

.body-pagamento-doar .container-opcao div {
    display: flex;
    align-items: center;
}

.body-pagamento-doar .container-opcao div .pix {
    width: 22%;
    margin-left: 8%;
}

.body-pagamento-doar .container-opcao div .boleto {
    width: 20%;
    margin-left: 6%;
}

.body-pagamento-doar .container-opcao div .credito {
    margin-left: 3%;
    display: flex;
    gap: 4%;
}

.body-pagamento-doar .container-opcao div p {
    margin-bottom: 0;
    white-space: nowrap;
}

.body-pagamento-doar .container-opcao div span {
    margin-left: 1vw;
    font-size: 12px;
    display: flex;
    align-items: center;
}

.body-pagamento-doar .container-opcao div span svg {
    margin-right: 0.3vw;
    font-size: 18px;
}



.modal-doar .modal-body{
    background-color: var(--secondary-color);
    border-radius: 8px;
    padding: 8% 7% 10% 7%;
    display: flex;
    align-items: center;
    flex-direction: column;
    color: var(--primary-text-color);
}

.modal-doar .modal-body img{
    width: 20%;
    margin-bottom: 7%;
}

.modal-doar .modal-body h2{
    font-size: 1.3rem;
    font-weight: 700;
    text-align: center;
}

.modal-doar .modal-body h6{
    font-size: 1.1rem;
    font-weight: 700;
}

.modal-doar .modal-body p{
    padding-top: 3%;
    font-size: 1rem;
    text-align: center;
}















/* celular e tablet */
@media screen and (max-width: 1080px) {
    .body-pagamento-doar {
        padding: 12% 0;
    }

    .body-pagamento-doar .cadeado {
        margin-bottom: 5%;
        margin-top: 15vh;
    }

    .body-pagamento-doar h3 {
        text-align: center;
        width: 85%;
    }

    .body-pagamento-doar .p1 {
        width: 80%;
    }

    .body-pagamento-doar .accordion {
        width: 85%;
        margin-bottom: 4%;
    }

    .body-pagamento-doar .accordion-header div p {
        padding: 5% 0 5% 6px !important;
    }

    .body-pagamento-doar .accordion-body {
        padding: 6% 4% !important;
        flex-direction: column;
    }

    .body-pagamento-doar .accordion-body img {
        width: 250px;
        margin-right: 0;
        margin-bottom: 7%;
        border-radius: 6px;
    }

    .body-pagamento-doar .accordion-body div p {
        font-size: 16px;
        text-align: center;
    }

    .body-pagamento-doar .accordion {
        max-height: 100%;
    }

    .body-pagamento-doar .container-opcao {
        width: 85%;
        margin-bottom: 4%;
        padding: 5% 5%;
    }

    .body-pagamento-doar .container-opcao div {
        flex-direction: column;
        align-items: start;
    }

    .body-pagamento-doar .container-opcao div span {
        margin-left: 0;
    }

    .body-pagamento-doar .container-opcao div span svg {
        margin-right: 1.3vw;
        background-color: var(--dark-red-color) !important;
    }
    .pagamento-doar-valor{
        margin-bottom: 2%;
    }
    .disabled span {
        margin-left: 2vw;
    }
    .body-pagamento-doar .credito-formulario h3{
        width: 100%;
        text-align: start;
        font-size: 1.4rem;
    }
    .body-pagamento-doar .accordion-header .cartoes-credito{
        margin-left: 2vw;
    }
    .body-pagamento-doar .accordion-header .cartoes-credito img{
        width: 2.5vh !important;
    }
}